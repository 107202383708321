import React from 'react';
import './PrimeDeatils.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const PrimeDeatils = () => {
  return (
    <div className="container">
   <ul className="sidebar-list">
        <li className="sidebar-item">
          <Link to="/InventoryManagement">
            <i className="fa fa-file-invoice"></i> Inventory Management
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/ReportDesign">
            <i className="fa fa-credit-card"></i> Reports
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/PaymentManagement">
            <i className="fa fa-credit-card-alt"></i> Credit and Collection Management
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/InvoiceFeature">
            <i className="fa fa-box"></i> e-Invoicing
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/Taxation">
            <i className="fa fa-file-alt"></i> Taxation & Compliance
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/BankReconciliation">
            <i className="fa fa-university"></i> Banking
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/ConnectedServices">
            <i className="fa fa-sitemap"></i> Net Capabilities
          </Link>
        </li>
        <li className="sidebar-item">
  <Link to="/plans">
    <i className="fa fa-inr"></i> Price
  </Link>
</li>

      </ul>

      
      <main className="content">
        <section className="content-section">
          <h2><i className="fa fa-file-invoice"></i> Create professional looking GST compliant invoice in seconds</h2>
          <p>As you grow, you need smart, professional-looking invoices that are GST compliant. Configure TallyPrime to print invoices as per your business needs. Generate e-invoices and print QR code instantly for hassle-free compliance.</p>
          <img src="/images/primeimg/create-professional-looking-gst-compliant-invoice-in-seconds.svg" alt="Multi-currency support" />
        </section>

        <section className="content-section">
          <h3><i className="fa fa-truck"></i> Flexible purchase and sales management</h3>
          
          <p>Does Tally offer different methods of purchasing goods? Does your sales team use alternative pricing strategies? Our purchase and sales management capabilities let you handle everything with ease.</p>
          <img src="/images/primeimg/1688723988519.png" alt="Multi-currency support" />

        </section>

        <section className="content-section">
          <h3><i className="fa fa-file-alt"></i> Multiple billing format</h3>
          <p>TallyPrime provides different billing formats to suit your business needs. Whether it's GST, excise, or delivery challans, you can choose a suitable invoice format for faster billing.</p>
          <img src="/images/primeimg/Optimise-paper-size-3.gif" alt="Multi-currency support" />
        </section>

        <section className="content-section">
          <h3><i className="fa fa-whatsapp"></i> Share invoices and orders via WhatsApp</h3>
          <p>Share invoices, orders, and more via WhatsApp, directly from TallyPrime. Save time and improve efficiency by sharing important documents with just a few clicks.</p>
          <img src="/images/primeimg/maxresdefault-1-1140x445.jpg" alt="Multi-currency support" />
        </section>

        <section className="content-section">
          <h3><i className="fa fa-dollar-sign"></i> Multi-currency support</h3>
          <p>Operate in multiple currencies and handle transactions across borders. TallyPrime allows you to generate reports in any currency, enabling smooth global operations.</p>
          <img src="/images/primeimg/00_Multi-Currency_in_Tally.ERP_1.gif" alt="Multi-currency support" />
        </section>

        <section className="content-section">
          <h3><i className="fa fa-tags"></i> Multiple price level</h3>
          <p>Create different pricing levels for wholesale, retail, customer, and more. Adapt your prices based on customer segments and requirements.</p>
          <img src="/images/primeimg/352-TallyPrimeBook.jpg" alt="Multi-currency support" />
        </section>
      </main>
    </div>
  );
};

export default PrimeDeatils;
