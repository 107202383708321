import React from 'react';
import './WhatsAppBusiness.css';
import { FaShareSquare, FaUsers, FaFileAlt, FaFileInvoiceDollar, FaClipboardList, FaInbox } from 'react-icons/fa';

const WhatsAppBusiness = () => {
    return (
        <div className="container">
            <div>
            </div>
            <p><h1>Make WhatsApp work for your business</h1>

                WhatsApp is a preferred choice for communication, and TallyPrime takes it a step further by integrating it into your business workflow.</p>
            <div className="card-grid">
                <div className="card">
                    <div className="card-icon-container">
                        <FaShareSquare className="card-icon" />
                    </div>
                    <h2>Quick and instant document sharing</h2>
                    <p>Share invoices, orders, reports, and more with a single click, enhancing efficiency.</p>
                </div>
                <div className="card">
                    <div className="card-icon-container">
                        <FaUsers className="card-icon" />
                    </div>
                    <h2>Bulk communication</h2>
                    <p>Efficiently manage and share multiple documents or communicate with multiple customers at once.</p>
                </div>
                <div className="card">
                    <div className="card-icon-container">
                        <FaFileAlt className="card-icon" />
                    </div>
                    <h2>Share documents in multiple formats</h2>
                    <p>Flexibility to share documents in PDF, JPEG and Excel.</p>
                </div>
                <div className="card">
                    <div className="card-icon-container">
                        <FaFileInvoiceDollar className="card-icon" />
                    </div>
                    <h2>From sharing invoices to payment collection</h2>
                    <p>Facilitate seamless payment collection through WhatsApp with embedded payment links and QR codes in the invoices.</p>
                </div>
                <div className="card">
                    <div className="card-icon-container">
                        <FaClipboardList className="card-icon" />
                    </div>
                    <h2>Ready-to-use templates</h2>
                    <p>Choose from a variety of pre-defined and approved message templates to ensure efficient and personalized communication.</p>
                </div>
                <div className="card">
                    <div className="card-icon-container">
                        <FaInbox className="card-icon" />
                    </div>
                    <h2>Dedicated inbox</h2>
                    <p>Manage all incoming messages from customers in a dedicated WhatsApp inbox.</p>
                </div>
            </div>
        </div>
    );
};

export default WhatsAppBusiness;
