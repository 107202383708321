import React, { useState } from "react";
import { FaUser, FaUsers, FaRegFileAlt, FaCreditCard, FaWhatsapp } from "react-icons/fa";  // Import icons
import "./TallyPrimePlan.css";
import { useNavigate } from "react-router-dom";


const TallyPrimePlan = () => {
  // Separate state for each section's year selection
  const [selectedYearSilver, setSelectedYearSilver] = useState("1");
  const [selectedYearGold, setSelectedYearGold] = useState("1");
  const [selectedRecharge, setSelectedRecharge] = useState("675");
  const [selectedRenewSubscription, setSelectedRenewSubscription] = useState("1");
  const navigate = useNavigate(); // Hook for navigation

  const handleBuyNowClick = () => {
    navigate("/contact"); // Navigate to the contact page
  };
  const priceDetails = {
    silver: {
      "1": { price: 750, gst: 135 },
      "3": { price: 2250, gst: 405 },
      "12": { price: 9000, gst: 1620 },
    },
    gold: {
      "1": { price: 2250, gst: 405 },
      "3": { price: 6750, gst: 1215 },
      "12": { price: 27000, gst: 4860 },
    },
    whatsappRecharge: {
      "675": { price: 675, gst: 122 },
      "2700": { price: 2700, gst: 486 },
    },
    renewSubscription: {
      "1": { price: 1300, gst: 234 },
    }
  };

  const handleYearChange = (event, section) => {
    const { value } = event.target;
    switch (section) {
      case "silver":
        setSelectedYearSilver(value);
        break; // Added missing break
      case "gold":
        setSelectedYearGold(value);
        break; // Added missing break
      case "renewSubscription":
        setSelectedRenewSubscription(value);
        break;
      default:
        break;
    }
};


  const handleRechargeChange = (event) => {
    setSelectedRecharge(event.target.value);
  };

  // Helper function to safely get price details
  const getPriceDetails = (section, year) => {
    return priceDetails[section] && priceDetails[section][year]
      ? priceDetails[section][year]
      : { price: 0, gst: 0 };  // Return a default value if undefined
  };

  return (
    <div className="tally-prime-container">
      {/* Header Section */}
      <h1 className="tally-prime-header" style={{justifyContent:"center"}}>
        <FaRegFileAlt className="icon" />
        TallyPrime Rental
      </h1>

      {/* Rental Section */}
      <div className="tally-prime-section">
        {/* Silver Rental */}
        <div className="tally-prime-card">
          <h2 className="tally-prime-card-title">
            <FaUser className="icon" />
            SILVER RENTAL
          </h2>
          <p>Single user edition</p>
          <p>For Standalone PCs</p>
          <p>
            Months: 
            <label>
              <input 
                type="radio" 
                name="rental-months-silver" 
                value="1" 
                checked={selectedYearSilver === "1"} 
                onChange={(e) => handleYearChange(e, "silver")} 
              />
              1 Month
            </label>
            <label>
              <input 
                type="radio" 
                name="rental-months-silver" 
                value="3" 
                checked={selectedYearSilver === "3"} 
                onChange={(e) => handleYearChange(e, "silver")} 
              />
              3 Months
            </label>
            <label>
              <input 
                type="radio" 
                name="rental-months-silver" 
                value="12" 
                checked={selectedYearSilver === "12"} 
                onChange={(e) => handleYearChange(e, "silver")} 
              />
              12 Months
            </label>
          </p>
          <h3 className="tally-prime-price">INR {getPriceDetails("silver", selectedYearSilver).price}</h3>
          <p>+18% GST (INR {getPriceDetails("silver", selectedYearSilver).gst})</p>
          <button className="tally-prime-button"onClick={handleBuyNowClick}>Renew</button>
        </div>
        
        {/* Gold Rental */}
        <div className="tally-prime-card">
          <h2 className="tally-prime-card-title">
            <FaUsers className="icon" />
            GOLD RENTAL
          </h2>
          <p>Unlimited multi-user edition</p>
          <p>For multiple PCs on LAN environment</p>
          <p>
            Months: 
            <label>
              <input 
                type="radio" 
                name="rental-months-gold" 
                value="1" 
                checked={selectedYearGold === "1"} 
                onChange={(e) => handleYearChange(e, "gold")} 
              />
              1 Month
            </label>
            <label>
              <input 
                type="radio" 
                name="rental-months-gold" 
                value="3" 
                checked={selectedYearGold === "3"} 
                onChange={(e) => handleYearChange(e, "gold")} 
              />
              3 Months
            </label>
            <label>
              <input 
                type="radio" 
                name="rental-months-gold" 
                value="12" 
                checked={selectedYearGold === "12"} 
                onChange={(e) => handleYearChange(e, "gold")} 
              />
              12 Months
            </label>
          </p>
          <h3 className="tally-prime-price">INR {getPriceDetails("gold", selectedYearGold).price}</h3>
          <p>+18% GST (INR {getPriceDetails("gold", selectedYearGold).gst})</p> 
          <button className="tally-prime-button"onClick={handleBuyNowClick}>Renew</button>
        </div>
      </div>

     
      {/* WhatsApp Recharge */}
      <h1 className="tally-prime-header" style={{justifyContent:"center"}}>
        <FaWhatsapp className="icon" />
        WhatsApp Recharge
      </h1>
      <div className="tally-prime-section">
        <div className="tally-prime-card">
          <h2 className="tally-prime-card-title">Recharge</h2>
          <p>Get your wallet recharged to
              continue uninterrupted messaging experience anytime, using WhatsApp
               for your Business.</p>
          <p>
            Recharge Amount: 
            <label>
              <input 
                type="radio" 
                name="recharge-amount" 
                value="675" 
                checked={selectedRecharge === "675"} 
                onChange={handleRechargeChange} 
              />
              INR 675
            </label>
            <label>
              <input 
                type="radio" 
                name="recharge-amount" 
                value="2700" 
                checked={selectedRecharge === "2700"} 
                onChange={handleRechargeChange} 
              />
              INR 2700
            </label>
          </p>
          <h3 className="tally-prime-price">INR {getPriceDetails("whatsappRecharge", selectedRecharge).price}</h3>
          <p>+18% GST (INR {getPriceDetails("whatsappRecharge", selectedRecharge).gst})</p>
          <button className="tally-prime-button" onClick={handleBuyNowClick}>Renew</button>
        </div>

      
      <div className="tally-prime-sections">
        <div className="tally-prime-card">
          <h2 className="tally-prime-card-title">Renew Subscription</h2>
          <p>
          Engage with your parties
uninterrupted with an active
WhatsApp for Business subscription
            <label>
              <input 
                type="radio" 
                name="renew-months" 
                value="1" 
                checked={selectedRenewSubscription === "1"} 
                onChange={(e) => handleYearChange(e, "renewSubscription")} 
              />
              1 Year
            </label>
          </p>
          <h3 className="tally-prime-price">INR {getPriceDetails("renewSubscription", selectedRenewSubscription).price}</h3>
          <p>+18% GST (INR {getPriceDetails("renewSubscription", selectedRenewSubscription).gst})</p>
          <br/><br/><br/><br/><br/>
          <button className="tally-prime-button"       onClick={handleBuyNowClick}
  >Renew</button>
        </div>
      </div>
    </div>
    </div>

  );
};

export default TallyPrimePlan;
