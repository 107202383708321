import React from 'react';
import { FaTruck, FaFileInvoice, FaWhatsapp, FaChartBar, FaMoneyBillWave, FaCloud, FaUniversity } from 'react-icons/fa'; // React Icons imports
import './ConnectedServices.css';

const ConnectedServices = () => {
  return (
    <div className="connected-services-container">

      <section className="service">
        <h2>
          <FaTruck /> Connected Service for E-Way Bill Generation
        </h2>
        <p>
          With TallyPrime’s fully connected e-way bill generation service, bid a sweet goodbye to the manual way of generating e-way bills. 
          Tally, being one of the recognized ISO certified GSP (GST Suvidha Provider), directly integrates with the e-way portal to seamlessly 
          generate e-way bills for you without manual intervention.
        </p>
      </section>

      <section className="service">
        <h2>
          <FaFileInvoice /> Connected Service for E-Invoice Generation
        </h2>
        <p>
          TallyPrime’s connected services make generating and printing e-invoices incredibly simple. With direct integration with the IRP, 
          you can automatically generate, upload the e-invoice data, get authenticated by the portal, and print the IRN & QR code without manual 
          intervention.
        </p>
      </section>

      <section className="service">
        <h2>
          <FaWhatsapp /> TallyPrime with WhatsApp
        </h2>
        <p>
          TallyPrime brings the power of instant communication via WhatsApp. Share invoices, orders, reports, and any other document directly from 
          TallyPrime. You can send invoices as soon as you create them, send bulk reminders, ledger reports, and more, in various file formats 
          like PDF, JPEG, or Excel. Plus, you can use pre-defined and personalized message templates.
        </p>
      </section>

      <section className="service">
        <h2>
          <FaChartBar /> Business Reports on Any Device, Anywhere
        </h2>
        <p>
          View important business reports such as bills receivable & payable, stock summary, sales/purchase register, profit & loss A/C, balance sheet, 
          and more from any device via a web browser. TallyPrime allows secure access to your business data even when you're away from the office.
        </p>
      </section>

      <section className="service">
        <h2>
          <FaMoneyBillWave /> Digital Payment Request
        </h2>
        <p>
          TallyPrime integrates with payment gateways like RazorPay and PayU to generate, share, and manage payment links. You can even use UPI to make payments. 
          Add payment QR codes and links to invoices and reports, and do it for single or multiple invoices at once. Plus, reconcile your books with the gateway portal.
        </p>
      </section>

      <section className="service">
        <h2>
          <FaCloud /> Remote Access
        </h2>
        <p>
          Secure remote access means you can access your data anywhere, anytime while ensuring your data stays secure. TallyPrime’s built-in security ensures 
          your information is protected, whether you’re in the office or on the go.
        </p>
      </section>

      <section className="service">
        <h2>
          <FaUniversity /> E-Banking Facility
        </h2>
        <p>
          TallyPrime’s seamless e-banking module allows you to make payments through e-fund transfer, electronic cheque, or electronic DD/PO between banks. 
          Simply export your transactions from Tally and upload them on the portal to complete the payment process, saving time and effort.
        </p>
      </section>
    </div>
  );
};

export default ConnectedServices;
