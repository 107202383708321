import React from "react";
import './ServicePage.css'; // Create a CSS file for styling
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const ServicePage = () => {
  const navigate = useNavigate(); // Initialize the navigate hook for navigation

  const services = [
    { name: "Support Desk", imgSrc: "/images/service/2.jpg", description: "Comprehensive support for all your queries and issues." },
    { name: "Field Service", imgSrc: "/images/service/1.jpg", description: "On-site service and support for all your business needs." },
    { name: "Customer Training", imgSrc: "/images/service/4.jpg", description: "Expert training sessions for your teams." },
    { name: "Remote Assistance", imgSrc: "/images/service/3.jpg", description: "Quick and effective remote support." },
    { name: "Technical Consulting", imgSrc: "/images/service/6.jpg", description: "Tailored technical advice to optimize your operations." },
    { name: "Managed Services", imgSrc: "/images/service/7.jpg", description: "End-to-end management of your IT infrastructure." },
  ];

  // Function to handle navigation to the contact page
  const handleNavigateToContact = () => {
    navigate("/contact"); // This will navigate to the contact page
  };

  return (
    <div className="service-page">
      <br />
      <br />
      <h1>Our Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-card"
            onClick={handleNavigateToContact} // Add onClick event to each card
          >
            <img src={service.imgSrc} alt={service.name} className="service-img" />
            <div className="service-info">
              <h2>{service.name}</h2>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicePage;
