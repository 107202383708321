import React from 'react';
import { FaUsers, FaShieldAlt, FaSyncAlt, FaChartLine } from 'react-icons/fa'; // Import relevant icons
import './TallyPrimeServer.css';

const TallyPrimeServer = () => {
  return (
    <div className="benefits-container">
      <header className="benefits-header">
        <br />
        <br />
        <br />

        <h1>Unlock the Power of TallyPrime with a Connected Solution</h1>
        <p>
          TallyPrime allows offices greater data access capabilities over the Tally network. This connected solution
          introduces greater concurrency, secured data access, and advanced monitoring capabilities. TallyPrime Server
          is key to designing secure and scalable business setups that improve your business efficiency.
        </p>
      </header>

      <section className="benefits-key">
        <h2>Key Benefits</h2>
        <div className="benefits-list">
          <Benefit
            title="High Concurrency"
            description="Performing operations with simultaneous access."
            content="Multiple users can perform activities such as recording vouchers, generating reports, and managing inventories at the same time, enabling greater efficiency and reduced wait time for tasks."
            icon={<FaUsers size={40} color="#4CAF50" />}
          />
          <Benefit
            title="Secured by Design"
            description="Providing controlled access to data files."
            content="With advanced security settings, TallyPrime Server provides users access to data while ensuring it is protected against unauthorized access."
            icon={<FaShieldAlt size={40} color="#4CAF50" />}
          />
          <Benefit
            title="High Reliability"
            description="Maintaining a high level of system availability."
            content="The robust infrastructure allows operations to continue smoothly even in situations of system interruptions, ensuring high reliability."
            icon={<FaSyncAlt size={40} color="#4CAF50" />}
          />
          <Benefit
            title="Business Process Optimization"
            description="Improved productivity & business performance."
            content="Enhanced monitoring and tracking capabilities allow business processes to be streamlined, resulting in increased productivity."
            icon={<FaChartLine size={40} color="#4CAF50" />}
          />
        </div>
      </section>
      {/* Pricing Section */}
<section className="pricing-section">
  <h2 className="pricing-header">TallyPrime Server Licensing & Pricing</h2>
  <div className="pricing-details">
    <div className="pricing-item">
      <div className="icon-wrapper">
        <FaChartLine size={50} color="#4CAF50" />
      </div>
      <h3>PERPETUAL</h3>
      <p>For medium and large enterprises</p>
      <p>(TallyPrime Server works with TallyPrime Gold license)</p>
      <p className="pricing-amount">
        <strong>INR 2,70,000</strong>
      </p>
      <p className="pricing-gst">
        +18% GST (<strong>INR 48,600</strong>)
      </p>
    </div>
  </div>
</section>

    </div>
  );
};

const Benefit = ({ title, description, content, icon }) => {
  return (
    <div className="benefit-item">
      <div className="benefit-icon-container">
        {icon}
      </div>
      <h3 className="benefit-title">{title}</h3>
      <p className="benefit-description">{description}</p>
      <p className="benefit-content">{content}</p>
    </div>
  );
};

export default TallyPrimeServer;
