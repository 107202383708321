import React, { useEffect } from "react";
import "./Gallery.css";

const Gallery = () => {
  // Disable right-click functionality
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the context menu from opening
    };

    // Attach event listener for right-click
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup the event listener when component is unmounted
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const memories = {
    "Memories": [
      { photo: "/images/office/2.jpg", year: "2021" },
      { photo: " /images/office/3.jpg", year: "2020" },
      { photo: "/images/office/4.jpg", year: "2019" },
      { photo: "/images/office/2.jpg", year: "2018" },
      { photo: "/images/office/3.jpg", year: "2021" },
      { photo: "/images/office/1.webp", year: "2020" },
      { photo: "/images/office/2.jpg", year: "2019" },
      { photo: "/images/office/3.jpg", year: "2018" },
      { photo: "/images/office/4.jpg", year: "2021" },
      { photo: "/images/office/6.jpg", year: "2020" },
      { photo: "/images/office/6.jpg", year: "2020" },
      { photo: "/images/office/5.jpg", year: "2019" },
      { photo: "/images/office/3.jpg", year: "2018" },
    ],
    "Success Moments": [
      { photo: "/images/office/3.jpg", year: "2023" },
      { photo: "/images/office/2.jpg", year: "2022" },
      { photo:"/images/office/6.jpg", year: "2021" },
    ],
    "Tour Moments": [
      { photo: "/images/office/4.jpg", year: "2022" },
      { photo: "/images/office/6.jpg", year: "2021" },
      { photo: "/images/office/2.jpg", year: "2020" },
    ],
    "Birthday Celebrations": [
      { photo: "/images/office/1.webp", year: "2023" },
      { photo: "/images/office/3.jpg", year: "2022" },
      { photo: "/images/office/5.jpg", year: "2021" },
    ],
  };

  return (
    <div className="frame-container">
      {Object.keys(memories).map((category) => (
        <div key={category}>
          <h1 className="frame-title">{category}</h1>
          <div className="frame-strings">
            {Array.from({ length: 4 }, (_, colIndex) => (
              <div className="frame-column" key={colIndex}>
                {memories[category]
                  .slice(colIndex * 3, colIndex * 3 + 3)
                  .map((item, index) => (
                    <div className="photo-container" key={index}>
                      <img
                        src={item.photo}
                        alt={`${category} ${index + 1}`}
                        className="photo"
                      />
                      <div className={`clip clip-${index % 4}`} />
                      <div className="year">{item.year}</div>
                    </div>
                  ))}
                <div className="heart" />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Gallery;
