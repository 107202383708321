// Header.js

import React, { useState, useEffect, useRef } from 'react';  // Import necessary hooks from React
import { FaBars } from 'react-icons/fa';  // Import FontAwesome bar icon for menu
import { Link, useNavigate } from 'react-router-dom';  // Import Link for navigation and useNavigate for programmatic navigation
import './Navigation.css';  // Import CSS for styling the header and navigation

const Header = ({ activeLink, onLinkClick }) => {  // Functional component with props activeLink and onLinkClick
  const [showMenu, setShowMenu] = useState(false);  // State to manage the visibility of the menu
  const [activeCategory, setActiveCategory] = useState(null);  // State to manage the currently active category
  const sidebarRef = useRef(null);  // Ref to track the sidebar element for outside click handling
  const navigate = useNavigate();  // useNavigate hook to programmatically navigate between routes

  // List of categories and their sub-items with image sources
  const categories = [
    {
      name: "Tally",
      subItems: [
        { name: "Payments", imgSrc: "/images/122n.jpg" },
        { name: "Invoicing", imgSrc: "/images/123n.jpeg" },
      ]
    },
    {
      name: "Service",
      subItems: [
        { name: "Support Desk", imgSrc: "/images/service/2.jpg" },
        { name: "Field Service", imgSrc: "/images/service/1.jpg" },
        { name: "Customer Training", imgSrc: "/images/service/4.jpg" },
        { name: "Remote Assistance", imgSrc: "/images/service/3.jpg" },
        { name: "Technical Consulting", imgSrc: "/images/service/6.jpg" },
        { name: "Managed Services", imgSrc: "/images/service/7.jpg" }
      ]
    },
    {
      name: "Web_Development",
      subItems: [
        { name: "Web Design", imgSrc: "/images/webdevelopment/5794869.jpg" },
        { name: "Web Hosting", imgSrc: "/images/webdevelopment/5.jpg" },
        { name: "Full-Stack Development", imgSrc: "/images/webdevelopment/6.jpg" },
        { name: "SEO Optimization", imgSrc: "/images/webdevelopment/1.jpg" },
        { name: "Content Management Systems (CMS)", imgSrc: "/images/webdevelopment/3.jpg" },
        { name: "E-commerce Development", imgSrc: "/images/webdevelopment/2.jpg" },
        { name: "Web Security", imgSrc: "/images/webdevelopment/4.jpg" }
      ]
    },
    {
      name: "Mobile_Development",
      subItems: [
        { name: "App Design", imgSrc: "/images/mobile/2.jpg" },
        { name: "Product", imgSrc: "/images/mobile/1.jpg" },
        { name: "App Testing", imgSrc: "/images/mobile/3.jpg" },
        { name: "Backend Integration", imgSrc: "/images/mobile/5.jpg" },
        { name: "User Interface (UI) (UX) Development", imgSrc: "/images/mobile/6.jpg" },
        { name: "Cross-Platform Development", imgSrc: "/images/mobile/4.jpg" },
        { name: "App Store Deployment", imgSrc: "/images/mobile/5297951-7905525.jpg" }
      ]
    },
    {
      name: "Hardware",
      subItems: [
        { name: "Service", imgSrc: "/images/hardware/4.jpg" },
        { name: "Repair", imgSrc: "/images/hardware/2.jpg" },
        { name: "Installation", imgSrc: "/images/hardware/1.jpg" },
        { name: "Firmware Updates", imgSrc: "/images/hardware/5.jpg" },
        { name: "Troubleshooting", imgSrc: "/images/hardware/2.jpg" },
        { name: "System Optimization", imgSrc: "/images/hardware/6.jpg" }
      ]
    },
    {
      name: "CCTV",
      subItems: [
        { name: "Dome & Bullet Cameras", imgSrc: "/images/cctv/3.jpg" },
        { name: "PTZ Cameras", imgSrc: "/images/cctv/1.jpg" },
        { name: "Network/IP Cameras", imgSrc: "/images/cctv/2.jpg" },
        { name: "Wireless Cameras", imgSrc: "/images/cctv/4.jpg" },
        { name: "CCTV Installation", imgSrc: "/images/cctv/5.jpeg" },
        { name: "CCTV Service", imgSrc: "/images/cctv/6.webp" },
      ]
    },
    {
      name: "Biometrics",
      subItems: [
        { name: "Fingerprint", imgSrc: "/images/bio/biometric-fingerprint-security-system.jpg" },
        { name: "Face Recognition", imgSrc: "/images/bio/MicrosoftTeams-image-246-1024x599 (1).jpg" },
        
      ]
    },    
    {
      name: "Printer",
    subItems: [
      { name: "Printer Setup", imgSrc: "/printer/4.webp" },
      { name: "Driver Installation", imgSrc: "/printer/5.png" },
      { name: "Print Quality Adjustment", imgSrc: "/printer/6.png" },
      { name: "Network Printer Configuration", imgSrc: "/printer/7.jpg" },
      { name: "Maintenance & Support", imgSrc: "/printer/8.webp" },
      ]
    }    
  ];

  // Function to toggle the visibility of the menu
  const toggleMenu = () => {
    setShowMenu(prevState => !prevState);
  };

  // Function to handle category clicks and navigate to specific routes if necessary
  const handleCategoryClick = (categoryName) => {
    setActiveCategory(prevCategory => (prevCategory === categoryName ? null : categoryName));
    setShowMenu(false);

    // If the "Hardware" category is clicked, navigate to the HardwareServices page
    if (categoryName === "Hardware") {
      navigate('/HardwareServices');
    }
     // If the "Hardware" category is clicked, navigate to the HardwareServices page
     if (categoryName === "CCTV") {
      navigate('/SecuritySystem');
    }
    if (categoryName === "Tally") {
      navigate('/tallyprime');
    }
    if (categoryName === "Mobile_Development") {
      navigate('/MobileDeveloper');
    }
    if (categoryName === "Web_Development") {
      navigate('/webcat');
    }
    if (categoryName === "Service") {
      navigate('/ServicePage');
    }
    if (categoryName === "Biometrics") {
      navigate('/BIO');
    }
    if (categoryName === "Printer") {
      navigate('/PrinterSoftware');
    }
  };

  // Function to log sub-item clicks (this can be customized for actual functionality)
  const handleSubItemClick = (subItem) => {
    console.log(`Sub-item clicked: ${subItem.name}`);
    setShowMenu(false);

  };

  // Close the sidebar menu if the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);  // Attach event listener when component mounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);  // Clean up event listener when component unmounts
    };
  }, []);

  // Find the active category data for rendering the sub-items
  const activeCategoryData = categories.find(category => category.name === activeCategory);

  return (
    <nav className="navbar">  {/* Navbar container */}\
        <div className="logo" style={{marginLeft:'-09px',position: 'fixed' }}> {/* Logo container */}
  <Link to="/" className={`nav-link ${activeLink === "/" ? "active" : ""}`}>
    <img src="/images/logo.png" alt="Logo" /> {/* Image source for logo */}
  </Link>
</div>
      <div className="menu-icon" style={{marginLeft:'120px',position: 'fixed'}} onClick={toggleMenu}>  {/* Menu icon for toggling the sidebar */}
        <FaBars />  {/* FontAwesome bar icon */}
      </div>
     <div className={`sidebar ${showMenu ? 'open' : ''}`} ref={sidebarRef}>  {/* Sidebar navigation */}
        <ul className="sidebar-nav">  {/* Sidebar list */}
          {categories.map(category => (  // Loop through categories to render sidebar links
            <li
              key={category.name}
              className={category.name === activeCategory ? 'active' : ''}  // Apply active class if category is selected
              onClick={() => handleCategoryClick(category.name)}  // Handle category click
            >
              {category.name}  {/* Category name */}
            </li>
          ))}
        </ul>

        {activeCategoryData && (  // If a category is active, display its sub-items
          <div className="sub-item-container">
            {/* <h4>{activeCategoryData.name}</h4>  Display category name */}
            <div className="card-list">  {/* List of sub-items */}
              {activeCategoryData.subItems.map((subItem, index) => (  // Loop through sub-items
                <div className="card" key={index} onClick={() => handleSubItemClick(subItem)}>  {/* Sub-item card */}
                  <img src={subItem.imgSrc} alt={subItem.name} />  {/* Sub-item image */}
                  <p>{subItem.name}</p>  {/* Sub-item name */}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
  


      <ul className={`nav-links ${showMenu ? 'open' : ''}`}>  {/* Navigation links */}
        <li>
          <Link to="/" className={`nav-link ${activeLink === "/" ? "active" : ""}`}>Home</Link>  {/* Link to Home page */}
        </li>
        <li>
          <Link to="/AboutPage" className={`nav-link ${activeLink === "/AboutPage" ? "active" : ""}`}>About</Link>  {/* Link to About page */}
        </li>
        <li>
          <Link to="/timelines" className={`nav-link ${activeLink === "/timelines" ? "active" : ""}`}>Milestones</Link>  {/* Link to Milestones page */}
        </li>
       
        <li>
          <Link to="/Client" className={`nav-link ${activeLink === "/Client" ? "active" : ""}`}>Customers</Link>  {/* Link to Customers page */}
        </li>
        <li>
          <Link to="/career_pages" className={`nav-link ${activeLink === "/CareerPage" ? "active" : ""}`}>
          Careers</Link>  {/* Link to Contact page */}
        </li>
        {/* <li>
          <Link to="/Gallery" className={`nav-link ${activeLink === "/Gallery" ? "active" : ""}`}>
          Gallery</Link> 
        </li> */}
        <li>
          <Link to="/contact" className={`nav-link ${activeLink === "/content_new" ? "active" : ""}`}>Contact</Link>  {/* Link to Contact page */}
        </li> 
      </ul>

    </nav>
  );
};

export default Header;  // Export the Header component
