import React from "react";
import { FaCogs, FaFileAlt, FaDatabase, FaLink } from 'react-icons/fa'; // Icons
import "./TallyDefinition.css";

function TallyDefinition() {
  return (
    <div className="tally-definition">
      <section className="tdl-section">
        <h2><FaCogs /> Tally Definition Language (TDL)</h2>
        <p>
          At the heart of Tally, TDL forms the key link between the application
          and the platform layer. TDL provides the user with flexibility and
          power to extend the default capabilities of Tally and integrate with
          external applications. TDL provides capabilities for rapid
          development, rendering, data management, and integration.
        </p>
        <p>
          The Tally Definition Language (TDL) along with the development suite
          TallyPrime Developer provides the strong technology backbone for
          developing solutions on Tally platform.
        </p>
        <div className="tdl-image">
          <img src="/images/tally/deploy-link-tdl-configuration-with-account-tally.gif" alt="TDL Example" />
        </div>
      </section>

      <section className="capabilities-section">
        <h2><FaFileAlt /> Capabilities of TallyPrime Developer</h2>
        <div className="capability-item">
          <h3><FaCogs /> Solution-ability & Customizability</h3>
          <p>
            TDL is a powerful tool in the hands of the programmer which enables
            customizability and solutioning capability in the product. TDL is
            platform independent, i.e., TDL programs remain the same irrespective
            of which operating system/network environment one uses. TDL
            automatically takes care of all developmental features of a solution.
          </p>
        </div>

        <div className="capability-item">
          <h3><FaFileAlt /> Multiple Output Capability</h3>
          <p>
            TDL can be used to generate output for multiple devices and in
            multiple formats. Whenever an output is generated it can be displayed
            on the screen, printed, transferred to a file in a particular format,
            mailed, or transferred to a webpage using HTTP protocol.
          </p>
        </div>

        <div className="capability-item">
          <h3><FaDatabase /> Data Management Capability</h3>
          <p>
            Data is stored and retrieved as objects in Tally. There are many
            internal objects predefined by the platform; using TDL, it is
            possible to create and manipulate this information with ease.
          </p>
        </div>

        <div className="capability-item">
          <h3><FaLink /> Integrate-ability</h3>
          <p>
            Tally has been designed to provide extensive integration capability
            which enables the developer community to exploit and deliver cross
            platform solutions.
          </p>
        </div>
      </section>

      <section className="why-programmers-section">
        <h2>Why Programmers Love TallyPrime Developer?</h2>
        <div className="programmer-benefits">
          <h3>Development Environment</h3>
          <p>
            TallyPrime Developer simplifies a programmer’s life with powerful
            features like the search engine, syntax highlighting, column
            selection, and auto-completion. The editor enables you to handle
            multiple projects, navigate across definitions, collapse and expand
            definitions, and debug the code.
          </p>
        </div>
      </section>
    </div>
  );
}

export default TallyDefinition;
