import React from 'react';
import './HardwareServices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faShieldAlt, faNetworkWired, faLaptop, faPrint, faTools } from '@fortawesome/free-solid-svg-icons';
import PrinterSoftware from '../PrinterSoftware/PrinterSoftware';

const HardwareServices = () => {
  return (
    <div className="hardware-services">
      <h2>Our Comprehensive Hardware Services</h2>
      <div className="services-container" style={{marginLeft:'80px'}}>

        <div className="service-item">
          <img src="images/71QtoB1MJIL-removebg-preview.png" alt="Desktop / Laptops" className="service-image" />
          {/* <span className="icon"><FontAwesomeIcon icon={faLaptop} /></span> */}
          <h3>Desktop / Laptops</h3>
          <p>We provide a full range of desktop and laptop services, from setups and configurations to repairs and maintenance, ensuring smooth performance. Our team can handle system upgrades, optimize system speed, and configure personalized settings. We also troubleshoot software and hardware issues for efficiency. If your device experiences slowdown, we can help improve performance with upgrades. Each service is backed by our commitment to quality and customer satisfaction.</p>
        </div>

        <div className="service-item">
          <img src="images/05-500x500.webp" alt="Peripherals" className="service-image" />
          {/* <span className="icon">🖱️</span> */}
          <h3>Peripherals</h3>
          <p>Optimize your setup with top-quality peripherals like keyboards, mice, monitors, and more, all compatible and seamlessly integrated with your system. We also offer guidance on selecting the right peripherals for productivity. From ergonomic designs to high-performance gear, we match devices with your needs. Our team ensures compatibility and installation support. Peripherals come with warranty and after-sales support for peace of mind.</p>
        </div>

        <div className="service-item">
          <img src="images/servers.jpg" alt="Servers" className="service-image" />
          {/* <span className="icon"><FontAwesomeIcon icon={faServer} /></span> */}
          <h3>Servers</h3>
          <p>Our robust server solutions ensure uninterrupted services and connectivity for your network, tailored to your organizational needs. We provide installation, maintenance, and monitoring services for servers. Our server experts work to minimize downtime and optimize server load balancing. We offer remote support and timely updates for reliability. Secure your data with our advanced server security options.</p>
        </div>

        {/* <div className="service-item">
          <img src="images/antivirus.webp" alt="Antivirus" className="service-image" />
          <span className="icon">🛡️</span>
          <h3>Antivirus</h3>
          <p>We offer antivirus and security solutions to protect your systems from malicious threats, ensuring data safety and peace of mind. Our services include regular system scans, real-time threat detection, and removal of malware. We also provide firewall configuration and customized security policies. Our team stays updated with the latest threats for robust protection. With us, your data remains safe and secure.</p>
        </div> */}

        <div className="service-item">
          <img src="images/ExitLag-Internet-Network-How-It-Works-and-the-Key-Components.webp" alt="Networking" className="service-image" />
          {/* <span className="icon"><FontAwesomeIcon icon={faNetworkWired} /></span> */}
          <h3>Networking</h3>
          <p>Comprehensive IT networking services, from routers and switches to complete infrastructure setups, designed for optimal connectivity. We assess your specific requirements and design a customized network layout. Installation and testing services ensure network efficiency and reliability. Our networking experts handle troubleshooting and technical support for smooth operations. Regular maintenance keeps your network at peak performance.</p>
        </div>

        <div className="service-item">
          <img src="/images/WhatsApp_Image_2024-10-21_at_4.47.15_PM-removebg-preview.png" alt="Security" className="service-image" />
          {/* <span className="icon"><FontAwesomeIcon icon={faShieldAlt} /></span> */}
          <h3>Security Solutions</h3>
          <p>Advanced security solutions including firewalls and encryption, providing robust protection for your hardware and data. We assess vulnerabilities and customize security plans. Our firewalls are configured to prevent unauthorized access. Encryption services protect sensitive data during transfers. Our solutions meet compliance standards, giving you confidence in data security. Dedicated support is available for quick response to security issues.</p>
        </div>

        {/* <div className="service-item">
          <img src="/printer/3.jpg" alt="Printers" className="service-image" />
          <span className="icon"><FontAwesomeIcon icon={faPrint} /></span>
          <h3>Printers</h3>
          <p>A wide selection of printers, installation, and support services for all business needs, from basic setups to advanced multifunction printers. We help you select the right printer model based on your needs. Installation includes setup and driver configuration. Regular maintenance is available to avoid disruptions. Our technicians are skilled in troubleshooting and resolving printing issues. Spare parts and supplies are available on demand.</p>
        </div> */}

        <div className="service-item">
          <img src="images/photo_technician-repairing-computer.webp" alt="System Installation" className="service-image" />
          {/* <span className="icon"><FontAwesomeIcon icon={faTools} /></span> */}
          <h3>System Installation</h3>
          <p>Our installation experts ensure all hardware and software are properly configured for maximum performance and reliability. We handle the full setup process, including cabling and configuration. Our team customizes settings for individual or organizational needs. We provide training on system usage and upkeep. Ongoing support is available for technical issues post-installation. Installations are optimized for performance and user satisfaction.</p>
        </div>
<PrinterSoftware/>
      </div>
    </div>
  );
};

export default HardwareServices;
