import React from "react";
import { FaSyncAlt, FaTools, FaDesktop, FaChalkboardTeacher, FaCogs, FaRegHandshake } from 'react-icons/fa'; // Import React Icons
import "./OurService.css"; // Assuming the CSS will be in this file

const OurService = () => {
  return (
    <div className="service-container1">
      <div className="service-item1">
        <div className="service-icon">
          <FaSyncAlt size={40} /> {/* AMC Synchronization Icon */}
        </div>
        <div className="service-text">
          <h3>AMC Synchronization</h3>
          <p>
          You can share data between two or more instances of TallyPrime using a client–server environment using Data Synchronisation. Data synchronisation can be initiated from the client to the server or vice versa, depending on your user-defined configurations.

The concept of ‘On Demand Synchronization’ – where systems actually align to a Tally.NET Synchronization Service, allowing each system to independently complete their work without needing to be ‘connected to each other live’. It has immediate benefits of removing the ‘manual’ coordination between two end-points before data is exchanged – which is a current typical behavior. It immediately increases the scale of ‘simultaneous uploads’ from ‘multiple points’ – as each system is now independently operating without clashing with another.

A robust mechanism to synchronize Master data, removing the need for convoluted approaches, giving both higher reliability, as well as allowing new forms of control architectures (example, allowing for a central system to ‘create and modify masters’, while the local systems are only allowed to enter transactions).

The concept of ensuring that a ‘delete’ action is appropriately propagated, so that both the source and destination remain consistent, without the need for manual intervention.
          </p>
          <p>
          Security in Data Synchronisation
The process of synchronisation is secured between the Head Office (Server) and Branches (Client) as data is transmitted using an encrypted format. Algorithm (Modified DES) which are proprietary in nature are used and understood by Tally.ERP 9 only.

Data synchronisation in Tally.ERP 9 is not web based, it happens using HTTP Protocol between Synchronisation Server and Client. Data is exchanged using Encrypted XML over HTTP. During the process of Synchronization via Tally.NET Server, the data or information is not stored anywhere in Tally.NET Server. Hence, the process of synchronisation is completely secured.
          </p>
        </div>
      </div>

      <div className="service-item1">
        <div className="service-icon">
          <FaTools size={40} /> {/* On-Site Support Icon */}
        </div>
        <div className="service-text">
          <h3>On-Site Support</h3>
          <p>
        Our on-site support service provides you with direct assistance from experienced technicians for the initial setup, software customization, and troubleshooting. Whether you're facing installation challenges or need advanced support, we offer hands-on help to ensure smooth and efficient operations at your location.
    </p>
    <p>
        Our experts perform detailed on-site assessments and provide tailored recommendations to enhance your business operations. From resolving critical issues to customizing settings, our team is committed to optimizing your Tally system’s performance on-site.
    </p>
        </div>
      </div>

      <div className="service-item1">
        <div className="service-icon">
          <FaDesktop size={40} /> {/* Remote Assistance Icon */}
        </div>
        <div className="service-text">
    <h3>Remote Assistance</h3> 
    <p>
        Our remote support service offers fast and efficient solutions for your Tally-related issues. Through secure remote access, our team can quickly resolve technical problems, troubleshoot errors, and guide you through complex configuration tasks. This minimizes downtime, ensuring your business continues to run smoothly.
    </p>
    <p>
        We provide assistance with software troubleshooting, configuration, and updates—all remotely—saving you time and minimizing disruptions to your operations. Remote assistance is available on-demand, offering rapid support whenever you need it.
    </p>
</div>

      </div>

      <div className="service-item1">
        <div className="service-icon">
          <FaChalkboardTeacher size={40} /> {/* Customized Training Icon */}
        </div>
        <div className="service-text">
  <h3>Tally Corporate Training</h3>
  <p>
    Our Tally Corporate Training programs are meticulously crafted to cater to your team’s specific needs. Whether you're new to Tally or looking for advanced strategies to maximize its potential, we provide interactive, in-depth sessions. Topics include inventory management, accounting, payroll, and financial reporting, ensuring your team gains practical, actionable skills.
  </p>
  <p>
    From beginner to advanced levels, our sessions are designed to help your team fully understand Tally's features and apply them effectively in real-world scenarios. To enhance learning, we incorporate hands-on exercises and real-world examples that make concepts easy to grasp and retain.
  </p>
  <p>
    Upon successful completion of the training, participants will receive an official eCertificate, recognizing their proficiency in Tally. This certification not only boosts confidence but also adds value to their professional credentials.
  </p>
</div>

      </div>

      <div className="service-item1">
        <div className="service-icon">
          <FaCogs size={40} /> {/* System Implementation Icon */}
        </div>
        <div className="service-text">
  <h3>Tally Implementation</h3>
  <p>
    Our expert consultants specialize in the seamless implementation of Tally, ensuring it integrates effortlessly into your organization's workflow. From the initial setup to customizing the software to match your unique business requirements, we ensure that Tally is configured to enhance your operations. Our team also provides detailed guidance on best practices for utilizing the software effectively across various departments.
  </p>
  <p>
    We ensure that your Tally implementation is perfectly aligned with your business processes, helping you optimize efficiency and minimize disruptions. Our services include data migration, ensuring a hassle-free transition of your existing records to Tally, as well as post-implementation support to address any challenges you may face. With our expertise, your organization will be equipped to leverage Tally's full potential for streamlined operations and improved productivity.
  </p>
</div>

      </div>

      <div className="service-item1">
        <div className="service-icon">
          <FaRegHandshake size={40} /> {/* Consultation Services Icon */}
        </div>
        <div className="service-text">
          <h3>Consultation Services</h3>
          <p>
            Our consultants offer in-depth analysis of your business processes and provide personalized recommendations to optimize your use of Tally. Whether you need advice on financial reporting, inventory management, or advanced features like multi-currency transactions, our team is here to help. We also assist with integrating Tally with other business applications for greater efficiency.
          </p>
          <p>
            We work with you to assess your current business needs and provide tailored solutions to help you maximize the full potential of Tally. From process automation to data integration, our consultation services ensure your business is running at peak performance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurService;
