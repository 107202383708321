import React, { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faStar, faCheckCircle, faAward, faLightbulb, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import "./Timeline.css"; // Assuming you have the CSS for styling

const Timeline = () => {
  const [isConfetti, setIsConfetti] = useState(false);

  // Disable right-click on the page
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // Trigger confetti effect on component mount
  useEffect(() => {
    setIsConfetti(true); // Trigger confetti effect
    const timer = setTimeout(() => {
      setIsConfetti(false); // Stop confetti after 3 seconds
    }, 50000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  const awards = [
    { year: "2006", title: "iMatrix Technologies had their journey in 2006", description: "The inception of iMatrix Technologies, focusing on innovative tech solutions.", presentedBy: "Founding Team", icon: faTrophy, image: "/images/awards/1.png", rating: 5 },
    {
      year: "2007",
      title: "Excellence Award Ts9 Rockers",
      description: "Awarded for outstanding performance and contributions in the Ts9 Rockers initiative, showcasing excellence and dedication.",
      presentedBy: "Ts9 Rockers Committee",
      icon: faStar,
      image: "/images/awards/29.png",
      rating: 4
  },
      { year: "2008", title: "Developed a strategy for technological solutions.", description: "Introduced strategic approaches to enhance service delivery and efficiency.", presentedBy: "Management Team", icon: faStar, image: "/images/awards/21.png", rating: 4 },
      {
        year: "2009",
        title: "Achievers Award H2 Topper - Dhruv",
        description: "Recognized for achieving top performance in the H2 category, showcasing remarkable dedication and skill.",
        presentedBy: "Dhruv Excellence Committee",
        icon: faCheckCircle,
        image: "/images/awards/22.png",
        rating: 3
    },
    {
      year: "2010",
      title: "Ballot Box Winner",
      description: "Honored for securing the highest votes in the Ballot Box competition, showcasing exceptional popularity and trust.",
      presentedBy: "Ballot Box Organization",
      icon: faAward,
      image: "/images/awards/23.png",
      rating: 4
  },     {
    year: "2011",
    title: "TS9 Championship League Winner",
    description: "Awarded for outstanding performance and winning the prestigious TS9 Championship League, demonstrating exceptional teamwork and excellence.",
    presentedBy: "TS9 League Committee",
    icon: faTrophy,
    image: "/images/awards/24.png",
    rating: 5
},
{
  year: "2012",
  title: "iMatrix Technologies Pvt Ltd Top 10 CP - South Zone",
  description: "Recognized as one of the Top 10 Channel Partners in the South Zone for exceptional performance and contributions.",
  presentedBy: "iMatrix Technologies Pvt Ltd",
  icon: faStar,
  image: "/images/awards/25.png",
  rating: 4
},  { year: "2013", title: "iMatrix Technologies evolved (Pvt) Ltd in 2013", description: "Transitioned to a Private Limited Company, expanding our service portfolio.", presentedBy: "Board of Directors", icon: faCheckCircle, image: "/images/awards/22.png", rating: 3 },
    { year: "2014", title: "5* Partners Tally", description: "Awarded the prestigious 5-Star Partner recognition for outstanding contributions to Tally solutions and exceptional customer service.", presentedBy: "Community Leaders", icon: faAward, image: "/images/awards/23.png", rating: 4 },
    {
      year: "2015",
      title: "Best Performer",
      description: "Awarded for outstanding individual performance and consistent excellence in achieving organizational goals.",
      presentedBy: "Performance Excellence Committee",
      icon: faTrophy,
      image: "/images/awards/24.png",
      rating: 5
  },  
      {
          year: "2016",
          title: "We received Achievers Award by NAKSH*TRA",
          description: "Awarded for outstanding achievements and contributions in the field of technology and innovation.",
          presentedBy: "Nakshatra Organization",
          icon: faStar,
          image: "/images/awards/25.png",
          rating: 4
      },
      {
          year: "2017",
          title: "TSS Independence Day Drive Group 1-Topper",
          description: "Secured top position in the TSS Independence Day Drive for exceptional performance and commitment.",
          presentedBy: "TSS Committee",
          icon: faCheckCircle,
          image: "/images/awards/26.png",
          rating: 5
      },
      {
          year: "2018",
          title: "Achieved Award for Top Trader",
          description: "Recognized as a top trader for exceptional trading performance and customer satisfaction.",
          presentedBy: "National Trading Council",
          icon: faTrophy,
          image: "/images/awards/24.png",
          rating: 5
      },
      {
          year: "2019",
          title: "Highest Value Contributors",
          description: "Awarded for being the highest value contributor in key business domains.",
          presentedBy: "Industry Leaders Forum",
          icon: faStar,
          image: "/images/awards/25.png",
          rating: 4
      },
      {
          year: "2020",
          title: "iMatrix Technologies Highest TSS in Q3-5* Partners",
          description: "Recognized for achieving the highest TSS in Q3 and maintaining 5-star partnerships.",
          presentedBy: "iMatrix Technologies",
          icon: faCheckCircle,
          image: "/images/awards/26.png",
          rating: 5
      },
      {
          year: "2021",
          title: "Awarded for Customer Acquisition",
          description: "Honored for exceptional performance in acquiring and retaining valuable customers.",
          presentedBy: "Customer Excellence Council",
          icon: faAward,
          image: "/images/awards/27.png",
          rating: 5
      },
      {
          year: "2022",
          title: "TSS Championship League Winner",
          description: "Crowned the winner of the prestigious TSS Championship League for outstanding team performance.",
          presentedBy: "TSS League Organizers",
          icon: faTrophy,
          image: "/images/awards/28.png",
          rating: 4
      },
      {
          year: "2023",
          title: "Best Performer TSP Drive",
          description: "Recognized as the best performer in the TSP Drive for exceptional dedication and results.",
          presentedBy: "TSP Drive Committee",
          icon: faLightbulb,
          image: "/images/awards/22.png",
          rating: 5
      },
      {
          year: "2024",
          title: "Champion in H1 5* CP's Excellence in TP-Retail-FY",
          description: "Awarded for exemplary excellence in TP retail operations and achieving 5-star recognition.",
          presentedBy: "TP Retail Excellence Forum",
          icon: faThumbsUp,
          image: "/images/awards/10.png",
          rating: 5
      },
    { year: "2025", title: "Our journey continues....", description: "Looking forward to new milestones and achievements in the coming years.", presentedBy: "All Employees", icon: faAward, image: "/images/talent-acquisition-hr-manager-onboarding-new-employees_1036975-209046.avif", rating: 4 }
  ];

  const awardImages = [
    "/images/awards/21.png",
    "/images/awards/29.png",
    "/images/awards/3.png",
    "/images/awards/10.png"
  ];

  return (
    <div className="timeline-container">
      {isConfetti && <ReactConfetti />}
      <br />
      <br />
      <h2>Awards and Accolades</h2>
      <div className="image-row">
        {awardImages.slice(0, 4).map((image, index) => (
          <img 
            key={index} 
            src={image} 
            alt={`Award ${index + 1}`} 
            className="row-image" 
          />
        ))}
      </div>
      <p className="timeline-description">
        A timeline of all the honorary accolades received by Tally over the years.
      </p>
      <div className="timeline">
        {awards.map((award, index) => (
          <div className="timeline-item" key={index}>
            <div className="timeline-year">{award.year}</div>
            <div className="timeline-content">
              <div className="content-details" style={{ width: '600px' }}>
                <FontAwesomeIcon icon={award.icon} className="timeline-icon" />
                <h3>{award.title}</h3>
                <p>{award.description}</p>
                <p className="timeline-presented">{award.presentedBy}</p>
                <div className="circular-image">
                  <img src={award.image} alt={`Award for ${award.year}`} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
