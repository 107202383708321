import React from 'react';
import { FaChartLine, FaBell, FaSyncAlt, FaUsers, FaFileInvoice } from 'react-icons/fa';
import './BizAnalyst.css';
import PlansAndPricing from './PlansAndPricing';
const BizAnalyst = () => {
  return (
    <div className="biz-analyst-container">
      <h1>What is Biz Analyst?</h1>
      <p>
        Biz Analyst – Mobile app for Tally users brings real-time information & allows you to access your Tally ERP9 data anytime / anywhere. It is securely synced with your Tally ERP9 installation.
      </p>
      <p>
        Multiple Reports, dashboard, and graphs allow you to make decisions in the office, at meetings, or out on the road. Biz Analyst is not only for the CEO and other senior management of the company but for everyone who uses tally data while on the move.
      </p>
      <p>
        Just a few clicks on the mobile phone, be it an iPhone or Android platform, you can count on Biz Analyst to give you the information you need.
      </p>

      <h2>Why Businesses choose Biz Analyst for growth?</h2>

      <div className="biz-features-grid">
        <div className="feature-itmesi">
          <FaChartLine className="feature-iconss" />
          <h3>Analyse & Grow your Sales</h3>
          <ul>
            <li>Analyse your sales by location, stock item, month, and more</li>
            <li>Follow up with inactive customers who have not ordered for a while</li>
            <li>Detailed Sales Analysis at the customer level</li>
            <li>Upsell stock items that you have not sold</li>
          </ul>
        </div>

        <div className="feature-itmesi">
          <FaBell className="feature-icons" />
          <h3>Send Outstanding Reminders and Get Paid faster</h3>
          <ul>
            <li>Multiple reminders at one click</li>
            <li>Share payment reminders via email, WhatsApp, SMS with a few taps</li>
            <li>Up to 50% reduction in payment delays</li>
            <li>Avoid poor cash flow because of delays in payments</li>
          </ul>
        </div>

        <div className="feature-itmesi">
          <FaSyncAlt className="feature-icons" />
          <h3>Stay connected to your business. Always</h3>
          <ul>
            <li>No dependency on an accountant</li>
            <li>Track your business anytime, anywhere</li>
            <li>It's 2024. Make smart data-driven decisions.</li>
          </ul>
        </div>

        <div className="feature-itmesi">
          <FaUsers className="feature-icons" />
          <h3>Increase sales team productivity</h3>
          <ul>
            <li>Outstanding follow-up with their customers</li>
            <li>Create sales orders, receipts while visiting customers</li>
            <li>Geo-tagged Check In/Out report of customer visits</li>
            <li>Add comments of each customer visit</li>
            <li>Easily give/remove limited access to the sales team</li>
          </ul>
        </div>

        <div className="feature-itmesi">
          <FaFileInvoice className="feature-icons" />
          <h3>Data Entry from App</h3>
          <ul>
            <li>Create Sales Invoices, Sales Orders, and Receipts from the app</li>
            <li>Create Purchase Invoices, Purchase Orders, and Payments</li>
            <li>Syncs automatically with Tally</li>
            <li>Share instantly via WhatsApp, Email, SMS, and more</li>
            <li>Save time & reduce errors</li>
          </ul>
        </div>
      </div>

      <PlansAndPricing/>


<div className="video-section">
  <h3>Watch Our Demo Video</h3>
  <video
    autoPlay
    loop
    muted
    disablePictureInPicture
    controlsList="nodownload noplaybackrate nofullscreen"
    className="feature-video"
    id="custom-video"
  >
    <source src="/images/video/3.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>

</div>

  );
};

export default BizAnalyst;
