import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './TallyShowcase.css';
import { Fade } from 'react-reveal';
import { FaBriefcase, FaServer, FaCloud, FaWhatsapp, FaWrench, FaMobile } from 'react-icons/fa';

const TallyShowcase = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleClick = (product) => {
    if (product === 'TallySoftware') {
      navigate('/TallySoftware'); // Use navigate instead of history.push
    }
    if (product === 'TallyPrimeServer') {
      navigate('/TallyPrimeServer'); // Use navigate instead of history.push
    }
    if (product === 'TallyAws') {
      navigate('/TallyAws'); // Use navigate instead of history.push
    }
    if (product === 'WhatsAppBusiness') {
      navigate('/WhatsAppBusiness'); // Use navigate instead of history.push
    }
    if (product === 'TallyPlatform') {
      navigate('/TallyPlatform'); // Use navigate instead of history.push
    }
    if (product === 'TallyDefinition') {
      navigate('/TallyDefinition'); // Use navigate instead of history.push
    }
    if (product === 'PrimeDeatils') {
      navigate('/PrimeDeatils'); // Use navigate instead of history.push
    }
    if (product === 'TallyDetails') {
      navigate('/TallyDetails'); // Use navigate instead of history.push
    }
    if (product === 'BizAnalyst') {
      navigate('/BizAnalyst'); // Use navigate instead of history.push
    }
    if (product === 'OurService') {
      navigate('/OurService'); // Use navigate instead of history.push
    }
  };

  const [expandedProduct, setExpandedProduct] = useState(null);

  const toggleDescription = (product) => {
    setExpandedProduct(expandedProduct === product ? null : product);
  };

  return (
    <div className="tallyshow-container">
      <h1 className="title">TallyPrime Products & Custom Solutions</h1>
      <div className="tallyshow-sections">

       {/* Products Section */}
<Fade bottom>
  <div className="tallyshow-section">
    <h2>Our Products</h2>
    <div className="product-list">
  
      <div className="product-item" onClick={() => handleClick('PrimeDeatils')}>
        <img src="/images/logotally/images (1).png" alt="TallyPrime" className="product-image" />
        <h3>TallyPrime</h3>
        <p>Fast and Powerful Business Management Software for your growing business</p>
        <button className="read-more" onClick={() => toggleDescription('PrimeDeatils')}>Read More</button>
        {expandedProduct === 'PrimeDeatils' && <p>Additional information about TallyPrime...</p>}
      </div>
      <div className="product-item" onClick={() => handleClick('TallySoftware')}>
        <img src="/images/logotally/product-tss.png" alt="Tally Software Services" className="product-image" />
        <h3>Tally Software Services</h3>
        <p>Collection of Connected Services for TallyPrime</p>
        <button className="read-more" onClick={() => toggleDescription('TallySoftware')}>Read More</button>
        {expandedProduct === 'TallySoftware' && <p>Additional information about Tally Software Services...</p>}
      </div>
      <div className="product-item" onClick={() => handleClick('TallyPrimeServer')}>
        <img src="/images/logotally/tallyprime-server-software-250x250.webp" alt="TallyPrime Server" className="product-image" />
        <h3>TallyPrime Server</h3>
        <p>Enterprise Class Product to improve your business efficiencies</p>
        <button className="read-more" onClick={() => toggleDescription('TallyPrimeServer')}>Read More</button>
        {expandedProduct === 'TallyPrimeServer' && <p>Additional information about TallyPrime Server...</p>}
      </div>
      <div className="product-item" onClick={() => handleClick('OurService')}>
  <img src="/images/logotally/tallyprime-on-cloud.png" alt="Tally business analyst" className="product-image" />
  <h3>Our Services</h3>
  <ul>
    <li><strong>AMC Sync</strong> </li>
    <li><strong>Direct Visits</strong> </li>
    <li><strong>Remote Support</strong> </li>
    <li><strong>Corporate Training</strong></li>
    <li><strong>Implementation</strong></li>
    <li><strong>Consultation</strong></li>
  </ul>
  <button className="read-more" onClick={() => toggleDescription('BizAnalyst')}>More Info</button>
  {expandedProduct === 'BizAnalyst' && <p>Additional details on Tally business analysis...</p>}
</div>
      <div className="product-item" onClick={() => handleClick('TallyAws')}>
        <img src="/images/logotally/04.png" alt="TallyPrime AWS" className="product-image" />
        <h3>TallyPrime powered by AWS</h3>
        <p>Access TallyPrime license and data virtually with AWS</p>
        <button className="read-more" onClick={() => toggleDescription('TallyAws')}>Read More</button>
        {expandedProduct === 'TallyAws' && <p>Additional information about TallyPrime powered by AWS...</p>}
      </div>
      <div className="product-item" onClick={() => handleClick('TallyDetails')}>
        <img src="/images/118-1181207_tally-erp-9-gst-logo-png-download-tally.png" alt="Tally ERP 9" className="product-image" />
        <h3>Tally 6.3 and 7.2 to Tally.ERP 9 to Tallyprime</h3>
        <p>A software that offers a range of functionalities, including financial accounting and management, banking, inventory management, and payroll.</p>
        <button className="read-more" onClick={() => toggleDescription('TallyDetails')}>Read More</button>
        {expandedProduct === 'TallyDetails' && <p>Additional information about Tally ERP 9...</p>}
      </div>
      <div className="product-item" onClick={() => handleClick('WhatsAppBusiness')}>
        <img src="/images/logotally/whatsapp-directly-from-tally.jpg" alt="Tally with WhatsApp" className="product-image" />
        <h3>TallyPrime with WhatsApp</h3>
        <p>Share invoices & documents instantly</p>
        <button className="read-more" onClick={() => toggleDescription('WhatsAppBusiness')}>Read More</button>
        {expandedProduct === 'WhatsAppBusiness' && <p>Additional information about TallyPrime with WhatsApp...</p>}
      </div>
      <div className="product-item" onClick={() => handleClick('BizAnalyst')}>
        <img src="/images/logotally/unnamed.png" alt="Tally business analyst" className="product-image" />
        <h3>Tally business analyst</h3>
        <p>Biz Analyst is a mobile application which securely syncs with TallyPrime & Tally ERP 9, allowing its user the access to business data from TallyPrime & Tally ERP 9 software anytime / anywhere, even when TallyPrime & Tally ERP 9 are offline.</p>
        <button className="read-more" onClick={() => toggleDescription('BizAnalyst')}>Read More</button>
        {expandedProduct === 'BizAnalyst' && <p>Additional information about Tally business analyst...</p>}
      </div>
      

    </div>
  </div>
</Fade>


        {/* Custom Solutions & Integrations Section */}
        <Fade bottom delay={200}>
          <div className="tallyshow-section">
            <h2>Custom Solutions & Integrations</h2>
            <div className="solution-list">
              <div className="solution-item" onClick={() => handleClick('TallyPlatform')}>
                <img src="/images/tally/black-woman-working-office-communication-600nw-2312841739.webp" alt="Custom Solutions" className="solution-image" />
                <h3>Extensions & Custom Solutions</h3>
                <p>Extend and Customize your Tally to fulfill specific business needs & boost efficiency</p>
                <button className="read-more" onClick={() => toggleDescription('TallyPlatform')}>Read More</button>
                {expandedProduct === 'TallyPlatform' && <p>Additional information about Extensions & Custom Solutions...</p>}
              </div>
              <div className="solution-item" onClick={() => handleClick('TallyDefinition')}>
                <img src="/images/tally/TDL-1.jpg" alt="Developer Suite" className="solution-image" />
                <h3>TallyPrime Developer</h3>
                <p>Develop and deploy solutions for TallyPrime with a comprehensive development suite</p>
                <button className="read-more" onClick={() => toggleDescription('TallyDefinition')}>Read More</button>
                {expandedProduct === 'TallyDefinition' && <p>Additional information about TallyPrime Developer...</p>}
              </div>
            </div>
          </div>
        </Fade>

      </div>
    </div>
  );
};

export default TallyShowcase;
