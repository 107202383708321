import React from "react";
import "./Taxation.css"; // Import CSS file for styles
import { FaRegThumbsUp, FaChartBar, FaFileInvoice, FaRegHandshake, FaMoneyBillAlt, FaTruckMoving } from "react-icons/fa"; // Importing some example icons

const Taxation = () => {
  const sections = [
    {
      title: "Connected GST Solution",
      content:
        "Simplify your GST compliance with TallyPrime. Generate e-invoices, reconcile GSTR-2A, and upload GST returns directly.",
      icon: <FaRegThumbsUp size={50} color="#0088cc" />,
    },
    {
      title: "TallyPrime Instant Demo",
      content:
        "Explore TallyPrime's GST features instantly with an interactive demo.",
      icon: <FaChartBar size={50} color="#0088cc" />,
    },
    {
      title: "E-invoices",
      content:
        "Quickly generate and manage e-invoices directly from TallyPrime, reducing errors and increasing efficiency.",
      icon: <FaFileInvoice size={50} color="#0088cc" />,
    },
    {
      title: "Auto-reconciliation of GSTR-2B and GSTR-2A",
      content:
        "Simplify your tax reconciliation process with automatic matching of GSTR data for compliance accuracy.",
      icon: <FaRegHandshake size={50} color="#0088cc" />,
    },
    {
      title: "Direct upload and filing of GST returns",
      content:
        "File your GST returns directly from TallyPrime with seamless upload features, eliminating manual efforts.",
      icon: <FaMoneyBillAlt size={50} color="#0088cc" />,
    },
    {
      title: "ITC at-risk insights in payables reports",
      content:
        "Get detailed insights into ITC risks within payables reports, ensuring compliance and better decision-making.",
      icon: <FaChartBar size={50} color="#0088cc" />,
    },
    {
      title: "Fully connected e-way bill solution",
      content:
        "Generate, manage, and track e-way bills easily within TallyPrime, simplifying logistics and compliance.",
      icon: <FaTruckMoving size={50} color="#0088cc" />,
    },
  ];

  return (
    <div className="taxation-container">
      {sections.map((section, index) => (
        <div
          className={`taxation-section taxation-section-${index}`}
          key={index}
        >
          <div className="section-content">
            <h2>{section.title}</h2>
            <p>{section.content}</p>
            <div className="icon-box">{section.icon}</div>
            {section.buttonText && (
              <button className="btn">{section.buttonText}</button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Taxation;
