import React from "react";
import { FaBox, FaBalanceScale, FaCog, FaChartLine, FaPlusCircle, FaMoneyBill } from "react-icons/fa"; // Import icons from react-icons
import "./InventoryManagement.css"; // External CSS file for styling

const InventoryManagement = () => {
  const sections = [
    {
      title: "Define unlimited groups, categories, batches, locations/godowns",
      content:
        "If your business has multiple product lines and each of them requires different types of grouping, TallyPrime makes it easy to define groups, categories, batches, and locations/godowns.",
      icon: <FaBox size={40} />, // Icon for this section
    },
    {
      title: "Flexible units of measure",
      content:
        "Handling multiple complexities like dual requirements for bag and box sizes is seamless. TallyPrime allows you to define and use multiple units of measure.",
      icon: <FaBalanceScale size={40} />, // Icon for this section
    },
    {
      title: "Manufacturing journal",
      content:
        "Record the entire cycle of manufacturing activity using TallyPrime. Maintain raw materials consumption, goods production, and by-product records.",
      icon: <FaCog size={40} />, // Icon for this section
    },
    {
      title: "Bill of material",
      content:
        "The comprehensive Bill of Material (BoM) feature in TallyPrime defines raw materials, components, and assemblies required for manufacturing.",
      icon: <FaChartLine size={40} />, // Icon for this section
    },
    {
      title: "Re-order level",
      content:
        "Reorder level feature in TallyPrime allows you to track stock levels and set minimum levels to ensure optimal stock replenishment.",
      icon: <FaPlusCircle size={40} />, // Icon for this section
    },
    {
      title: "Multiple stock valuation",
      content:
        "TallyPrime lets you value stock according to various methods, ensuring accurate records. Methods include Average Cost, FIFO, and others.",
      icon: <FaMoneyBill size={40} />, // Icon for this section
    },
  ];

  return (
    <div className="inventory-container">
      {sections.map((section, index) => (
        <div
          className={`inventory-section inventory-section-${index}`}
          key={index}
        >
          <div className="inventory-section-content">
            <h3>{section.title}</h3>
            <p>{section.content}</p>
          </div>
          <div className="inventory-section-icon">
            {section.icon}
          </div>
        </div>
      ))}
    </div>
  );
};

export default InventoryManagement;
