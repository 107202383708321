import React, { useState, useEffect } from 'react';
import './Client.css';

const Client = () => {
  const initialImages = [
    { img: '/images/Customer_logo/1.jpg', companyImg: '/images/Customer_logo/1.jpg' },
  { img: '/images/Customer_logo/2.jpg', companyImg: '/images/Customer_logo/2.jpg' },
  { img: '/images/Customer_logo/3.png', companyImg: '/images/Customer_logo/3.png' },
  { img: '/images/Customer_logo/4.jpg', companyImg: '/images/Customer_logo/4.jpg' },
  { img: '/images/Customer_logo/5.jpg', companyImg: '/images/Customer_logo/5.jpg' },
  { img: '/images/Customer_logo/6.png', companyImg: '/images/Customer_logo/6.png' },
  { img: '/images/Customer_logo/7.png', companyImg: '/images/Customer_logo/7.png' },
  { img: '/images/Customer_logo/8.jpg', companyImg: '/images/Customer_logo/8.jpg' },
  { img: '/images/Customer_logo/9.gif', companyImg: '/images/Customer_logo/9.gif' },
  { img: '/images/Customer_logo/10.jpg', companyImg: '/images/Customer_logo/10.jpg' },
  { img: '/images/Customer_logo/11.JPG', companyImg: '/images/Customer_logo/11.JPG' },
  { img: '/images/Customer_logo/12.JPG', companyImg: '/images/Customer_logo/12.JPG' },
  { img: '/images/Customer_logo/13.JPG', companyImg: '/images/Customer_logo/13.JPG' },
  { img: '/images/Customer_logo/14.JPG', companyImg: '/images/Customer_logo/14.JPG' },
  { img: '/images/Customer_logo/15.JPG', companyImg: '/images/Customer_logo/15.JPG' },
  { img: '/images/Customer_logo/16.JPG', companyImg: '/images/Customer_logo/16.JPG' },
  { img: '/images/Customer_logo/17.jpg', companyImg: '/images/Customer_logo/17.jpg' },
  { img: '/images/Customer_logo/18.png', companyImg: '/images/Customer_logo/18.png' },
  { img: '/images/Customer_logo/19.jpg', companyImg: '/images/Customer_logo/19.jpg' },
  { img: '/images/Customer_logo/20.png', companyImg: '/images/Customer_logo/20.png' },
  { img: '/images/Customer_logo/21.jpg', companyImg: '/images/Customer_logo/21.jpg' },
  { img: '/images/Customer_logo/22.jpg', companyImg: '/images/Customer_logo/22.jpg' },
  { img: '/images/Customer_logo/23.png', companyImg: '/images/Customer_logo/23.png' },
  { img: '/images/Customer_logo/24.png', companyImg: '/images/Customer_logo/24.png' },
  { img: '/images/Customer_logo/25.png', companyImg: '/images/Customer_logo/25.png' },
  { img: '/images/Customer_logo/26.jpg', companyImg: '/images/Customer_logo/26.jpg' },
  { img: '/images/Customer_logo/27.jpg', companyImg: '/images/Customer_logo/27.jpg' },
  { img: '/images/Customer_logo/28.png', companyImg: '/images/Customer_logo/28.png' },
  { img: '/images/Customer_logo/29.png', companyImg: '/images/Customer_logo/29.png' },
  { img: '/images/Customer_logo/30.jpg', companyImg: '/images/Customer_logo/30.jpg' },
  { img: '/images/Customer_logo/31.jpg', companyImg: '/images/Customer_logo/31.jpg' },
  { img: '/images/Customer_logo/32.jpg', companyImg: '/images/Customer_logo/32.jpg' },
  { img: '/images/Customer_logo/33.png', companyImg: '/images/Customer_logo/33.png' },
  { img: '/images/Customer_logo/34.jpg', companyImg: '/images/Customer_logo/34.jpg' },
  { img: '/images/Customer_logo/35.png', companyImg: '/images/Customer_logo/35.png' },
  { img: '/images/Customer_logo/36.jpg', companyImg: '/images/Customer_logo/36.jpg' },
  { img: '/images/Customer_logo/37.png', companyImg: '/images/Customer_logo/37.png' },
  { img: '/images/Customer_logo/38.jpg', companyImg: '/images/Customer_logo/38.jpg' },
  { img: '/images/Customer_logo/39.png', companyImg: '/images/Customer_logo/39.png' },


  { img: '/images/clintimage/india-cements-logo.png', companyImg: '/images/clintimage/india-cements-logo.png' },
  { img: '/images/newcust/3.jpeg', companyImg: '/images/newcust/3.jpeg' },
  { img: '/images/newcust/4.jpg', companyImg: '/images/newcust/4.jpg' },
  { img: '/images/newcust/5.png', companyImg:  '/images/newcust/5.png' },
  { img: '/images/newcust/6.jpeg', companyImg: '/images/newcust/6.jpeg' },
  { img: '/images/newcust/8.png', companyImg: '/images/newcust/8.png' },
  { img: '/images/newcust/9.jpg', companyImg: '/images/newcust/9.jpg' },

  ];

  // State to manage flipped images
  const [flipped, setFlipped] = useState(Array(initialImages.length).fill(false));
  const [currentImages, setCurrentImages] = useState(initialImages);

  // Dynamically change images every 1 second
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the currentImages array to flip the images
      setCurrentImages((prevImages) =>
        prevImages.map((imageObj) => ({
          img: imageObj.companyImg,   // Swap the images
          companyImg: imageObj.img,
        }))
      );

      // Flip one random image at a time
      setFlipped((prev) => {
        const newFlipped = [...prev];
        const randomIndex = Math.floor(Math.random() * initialImages.length);
        newFlipped[randomIndex] = !newFlipped[randomIndex]; // Toggle the flip state
        return newFlipped;
      });
    }, 20000); // 1 second interval

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [initialImages.length]);

  // Handle manual flip animation on image click
  const handleFlip = (index) => {
    setFlipped((prev) => {
      const newFlipped = [...prev];
      newFlipped[index] = !newFlipped[index]; // Toggle the flipped state
      return newFlipped;
    });
  };

  return (
    <div className="client-section">
      <h2 className="client-header">Our Customers</h2>
      <div className="gallery-container">
        {currentImages.map((imgObj, index) => (
          <div key={index} className={`gallery-item ${flipped[index] ? 'flipped' : ''}`} onClick={() => handleFlip(index)}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={imgObj.img} alt={`Image ${index + 1}`} />
              </div>
              <div className="flip-card-back">
                <img src={imgObj.companyImg} alt={`Company Image ${index + 1}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Client;
