import React from 'react';
import './InvoiceFeature.css'; // Include your CSS file for styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoiceDollar,
  faFileUpload,
  faPlayCircle,
  faTruck,
  faChartBar,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

// InvoiceFeature component
const InvoiceFeature = ({ title, content, icon, buttonText, link, index }) => (
  <div className={`feature-section feature-section-${index}`}>
    <div className="feature-icon">
      <FontAwesomeIcon icon={icon} size="3x" />
    </div>
    <div className="feature-text">
      <h3>{title}</h3>
      <p>{content}</p>
      {buttonText && link && (
        <a href={link} className="feature-button">
          {buttonText}
        </a>
      )}
    </div>
  </div>
);

// Features data array
const features = [
  {
    title: 'Generate e-invoices instantly',
    content:
      'Generate e-invoices instantly with no manual intervention. This ensures seamless GST compliance by automatically generating e-invoice JSON files, QR codes, or an invoice without manual intervention.',
    icon: faFileInvoiceDollar,
  },
  {
    title: 'Bulk e-invoicing',
    content:
      'In certain business situations, you may require generating bulk e-invoices. TallyPrime offers a bulk e-invoicing feature, making it easy to generate and maintain e-invoice JSON files for GST compliance.',
    icon: faFileUpload,
  },
  {
    title: 'TallyPrime instant demo',
    content:
      'Try the instant e-invoicing demo and explore the seamless e-invoicing process on TallyPrime.',
    icon: faPlayCircle,
    link: '#', // Add actual link here
  },
  {
    title: 'Generate e-way bill along with e-invoice',
    content:
      'By clicking the e-waybill button, TallyPrime lets you generate an e-way bill along with your e-invoice. This eliminates manual work and ensures GST compliance.',
    icon: faTruck,
  },
  {
    title: 'e-Invoice reports',
    content:
      'TallyPrime generates accurate e-invoice reports giving you a clear understanding of your compliance status. From pending e-invoices to filed reports, Tally helps you keep track of everything.',
    icon: faChartBar,
  },
  {
    title: 'Online e-invoice cancellation',
    content:
      'TallyPrime allows you to cancel an e-invoice directly from the software if needed. This ensures seamless GST compliance in case of any changes.',
    icon: faTimesCircle,
  },
];

// Main component to render all features
const InvoiceFeatureList = () => (
  <div className="feature-container">
    {features.map((feature, index) => (
      <InvoiceFeature key={index} {...feature} index={index} />
    ))}
  </div>
);

export default InvoiceFeatureList;
