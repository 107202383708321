import React from "react";
import "./PlansAndPricing.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const PlansAndPricing = () => {
  const navigate = useNavigate(); // Initialize the navigate hook for navigation
  // Function to handle navigation to the contact page
  const handleNavigateToContact = () => {
    navigate("/contact"); // This will navigate to the contact page
  };
  const plans = [
    {
      duration: "1 Year Plan",
      price: "₹3,300",
      originalPrice: "₹3,600",
      discount: "₹300 OFF",
      details: "Price for 1 year without GST | Introductory offer applied",
      devices: "2 Devices",
      note: "1 Desktop App + 1 Mobile App",
      additional: "Get additional device for ₹3,000 each",
      bulkDiscount: "10% off on 5 or more additional any devices apart from base plan",
    },
    {
      duration: "3 Year Plan",
      price: "₹6,600",
      originalPrice: "₹9,000",
      discount: "₹600 OFF",
      details: "Price for 3 years without GST | Introductory offer applied",
      devices: "2 Devices",
      note: "1 Desktop App + 1 Mobile App",
      additional: "Get additional device for ₹2,000 each",
      bulkDiscount: "10% off on 5 or more additional any devices apart from base plan",
    },
    {
      duration: "5 Year Plan",
      price: "₹9,900",
      originalPrice: "₹13,500",
      discount: "₹3,600 OFF",
      details: "Price for 5 years without GST | Introductory offer applied",
      devices: "2 Devices",
      note: "1 Desktop App + 1 Mobile App",
      additional: "Get additional device for ₹2,000 each",
      bulkDiscount: "10% off on 5 or more additional any devices apart from base plan",
    },
  ];

  const features = [
    "All features",
    "1 Tally license support",
    "Onboarding & Training support worth ₹900 free",
    "Lifetime product support free",
  ];

  return (
    <div className="pricing-container">
      <h1 className="pricing-title">Plans and Pricing</h1>
      <div className="plans">
        {plans.map((plan, index) => (
          <div key={index} className="plan-card">
            <h3 className="plan-duration">{plan.duration}</h3>
            <h2 className="plan-type">Business</h2>
            <p className="plan-price">
              <span className="original-price">{plan.originalPrice}</span> {plan.price}
            </p>
            <p className="discount">{plan.discount}</p>
            <p className="plan-details">{plan.details}</p>
            <div className="devices">
              <p className="device-count">{plan.devices}</p>
              <p className="device-note">{plan.note}</p>
            </div>
            <p className="additional">{plan.additional}</p>
            <p className="bulk-discount">{plan.bulkDiscount}</p>
          </div>
        ))}
      </div>
      <button className="trial-button"   onClick={handleNavigateToContact} >Start Free 7 Day Trial</button>
      <div className="features-section">
        <h3>Business plan includes</h3>
        <ul className="features-list">
          {features.map((feature, index) => (
            <li key={index} className="feature">
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlansAndPricing;
