import React from "react";
import "./TallyPlatform.css";

function TallyPlatform() {
  return (
    <div className="tally-platform">
      <section className="header">
        <h1>Do More with Tally</h1>
        <p>
          Tally is designed to manage and simplify business processes through a
          robust ecosystem and integration, built by thousands of developers.
        </p>
        <img
          src="/images/tallyprime-on-a-cloud-service (1).jpg"
          alt="Tally Overview"
          className="header-image"
        />
      </section>

      <section className="development-platform">
        <h2><i className="fas fa-code"></i> Tally – As a Development Platform</h2>
        <p>
          Tally is not only an application but also a comprehensive development
          environment that enables programmers to build extensions, customize
          functions, and integrate third-party solutions using Tally Definition
          Language (TDL). This allows programmers to extend the default
          application without compromising its core, flexibility, and unique
          offerings, which is the power of Tally.
        </p>

        <div className="features">
          <div className="feature-item">
            <h3><i className="fas fa-shield-alt"></i> Retain the Power of Tally</h3>
            <p>
              Extensions and customizations retain the core features of Tally,
              so you never lose what makes Tally unique.
            </p>
          </div>
          <div className="feature-item">
            <h3><i className="fas fa-bolt"></i> Rapid Development</h3>
            <p>
              Rapid creation and integration for Tally Fast.
            </p>
          </div>
          <div className="feature-item">
            <h3><i className="fas fa-plug"></i> Plug and Play</h3>
            <p>
              Add extensions and use them as you prefer. Tally does it all.
            </p>
          </div>
        </div>
      </section>
      <section className="extensions">
  <h2><i className="fas fa-puzzle-piece"></i> Extensions</h2>
  <p>
    Extensions add functionality to suit other industry features. You can
    expand TallyPrime across the TDL ecosystem to add and upgrade extensions. These extensions are designed to cater to specific business requirements and enhance the overall TallyPrime experience.
  </p>
  <p>
    TallyPrime extensions offer seamless integration with other software and systems, ensuring that your business operations remain smooth and efficient. Whether you need to integrate payment gateways, automate processes, or extend the capabilities of your existing setup, extensions can help achieve these goals effortlessly.
  </p>
  <h3>Key Benefits of TallyPrime Extensions:</h3>
  <ul>
    <li><strong>Customization:</strong> Tailor TallyPrime to meet specific business needs.</li>
    <li><strong>Scalability:</strong> Grow your business with extensions that enhance core functionality.</li>
    <li><strong>Ease of Use:</strong> Simple to install and configure, no technical expertise required.</li>
    <li><strong>Improved Efficiency:</strong> Automate repetitive tasks and reduce manual intervention.</li>
    <li><strong>Integration:</strong> Easily connect to third-party applications and services.</li>
  </ul>
  <h3>Popular Extensions Available for TallyPrime:</h3>
  <ul>
    <li><strong>GST Filing Integration:</strong> Simplify GST filing with automated reports and returns generation.</li>
    <li><strong>E-Way Bill Generation:</strong> Quickly create and manage e-way bills for transportation compliance.</li>
    <li><strong>Inventory Management:</strong> Advanced tools to track and manage inventory in real-time.</li>
    <li><strong>Accounting Automation:</strong> Automate invoicing, payments, and reconciliations.</li>
  </ul>
  <p>
    By adding these extensions, TallyPrime becomes a more powerful tool, capable of handling various business processes seamlessly. Discover the potential of TallyPrime with extensions today!
  </p>
  <img
    src="/images/tallynwq/image (1).png"
    alt="Extensions"
    className="section-image"
  />
</section>

<section className="customization">
  <h2><i className="fas fa-wrench"></i> Customization</h2>
  <p>
    Looking for features or solutions that require a tailored approach? 
    Custom solutions for Tally give you the tools for your specific needs, allowing you to extend functionality, integrate with third-party applications, and automate processes that are unique to your business operations.
  </p>
  <h3>Why Customize TallyPrime?</h3>
  <p>
    TallyPrime is a powerful accounting and business management software that can be easily customized to suit your industry-specific requirements. With the ability to adapt the software to your processes, you can achieve greater efficiency, accuracy, and flexibility.
  </p>
  <h3>Customization Features Include:</h3>
  <ul>
    <li><strong>Industry-Specific Solutions:</strong> Customize Tally for different industries like retail, manufacturing, or service, by adding relevant features and reports.</li>
    <li><strong>Automated Workflows:</strong> Set up automated workflows to streamline tasks, minimize manual errors, and reduce time spent on repetitive processes.</li>
    <li><strong>Integrations:</strong> Integrate Tally with other systems, such as CRM, payment gateways, or ERP, to ensure smooth data flow and real-time updates.</li>
    <li><strong>Custom Reports:</strong> Create tailored reports to analyze your business data in a way that makes sense for your operations and goals.</li>
    <li><strong>User Roles & Permissions:</strong> Define user roles, restricting access to sensitive data, and ensuring that each team member has access to the right tools.</li>
  </ul>
  <h3>Popular Customization Options:</h3>
  <ul>
    <li><strong>Custom Fields:</strong> Add specific fields to track additional data relevant to your business operations.</li>
    <li><strong>Custom Invoices:</strong> Design invoices, purchase orders, and bills with your brand’s logo, colors, and required details.</li>
    <li><strong>Custom Scripts:</strong> Develop custom scripts using TDL (Tally Definition Language) to automate and personalize your workflows.</li>
    <li><strong>Third-Party Add-Ons:</strong> Enhance TallyPrime’s functionality by integrating third-party add-ons tailored for your business needs.</li>
  </ul>
  <p>
    With TallyPrime's customization capabilities, you can ensure that your software matches the way you do business, offering maximum productivity and efficiency.
  </p>
  <img
    src="/images/tallynwq/customise-your-tally.png"
    alt="Customization"
    className="section-image"
  />
</section>
<section className="integrations">
  <h2><i className="fas fa-sync-alt"></i> Integrations</h2>
  <p>
    Tally Prime integrates third-party solutions seamlessly, giving you the
    flexibility to design and enhance the entire software ecosystem. By connecting Tally Prime with other business tools and platforms, you can streamline your operations and enhance your workflow across various functions, such as accounting, inventory, payroll, and more.
  </p>
  <h3>Why Integrate with Tally Prime?</h3>
  <p>
    Integration with Tally Prime provides significant advantages by linking it with tools that your business already uses. Whether you are managing payroll, inventory, CRM systems, or e-commerce platforms, integrating Tally Prime ensures that all your systems work together in real-time, making your processes more efficient, error-free, and automated.
  </p>
  <h3>Key Benefits of Tally Prime Integrations:</h3>
  <ul>
    <li><strong>Automation:</strong> Automate key business functions, such as invoicing, payment processing, and data synchronization.</li>
    <li><strong>Real-Time Data Sync:</strong> Ensure that your data across different platforms is updated instantly, reducing the chances of discrepancies.</li>
    <li><strong>Centralized Data:</strong> Bring all your data into one platform, improving reporting accuracy and decision-making.</li>
    <li><strong>Improved Efficiency:</strong> Reduce manual entry and errors by syncing data automatically across systems.</li>
    <li><strong>Cost Savings:</strong> Minimize operational costs by using automated processes and reducing the need for manual data handling.</li>
  </ul>
  <h3>Popular Integrations Available for Tally Prime:</h3>
  <ul>
    <li><strong>Payment Gateway Integration:</strong> Connect Tally Prime with popular payment gateways like PayPal, Razorpay, and Stripe to automate payment reconciliation and reduce manual effort.</li>
    <li><strong>CRM Integration:</strong> Integrate with CRM systems like Salesforce or Zoho to ensure seamless synchronization of customer data and transactions.</li>
    <li><strong>Inventory Management Systems:</strong> Sync inventory data between Tally Prime and third-party inventory management systems to keep track of stock levels in real-time.</li>
    <li><strong>E-commerce Platforms:</strong> Easily integrate with online platforms like Shopify, WooCommerce, and Magento for automatic syncing of sales and inventory data.</li>
    <li><strong>Payroll Integration:</strong> Simplify employee salary management and compliance by integrating with payroll systems such as GreytHR, Zoho Payroll, or QuickBooks Payroll.</li>
  </ul>
  <p>
    Whether you're looking to integrate payment systems, e-commerce platforms, or third-party applications, Tally Prime offers the flexibility and tools you need to create a seamless workflow across your entire business ecosystem. Enhance the functionality of your Tally Prime software today by exploring its powerful integration capabilities.
  </p>
  <img
    src="/images/tallynwq/api-iIntegrations-with-tallyprime.webp"
    alt="Integrations"
    className="section-image"
  />
</section>

    </div>
  );
}

export default TallyPlatform;
