import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./AboutPage.css";

const AboutPage = () => {
  const navigate = useNavigate(); 

  return (
    <div className="about-page">
      {/* Top Section */}
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
      <section className="about-top">
        <div className="about-top-text">
          <h1 style={{marginBottom:'-50px'}} >About </h1>
          <p>
          iMatrix Technologies began its humble journey in 2006, with a clear vision to deliver innovative technical solutions and exceptional customer service. Over the years, we have developed fine-tuned, successful strategies for our vendors, leveraging solid expertise to push the boundaries of service delivery. Our commitment to excellence has helped us redefine the customer experience, taking it to new dimensions.

          </p>
          <p>In 2013, iMatrix Technologies was formally incorporated as iMatrix Technologies Private Limited, further cementing our position in the industry. Our dedication to serving customers from all sectors is driven by our belief in the power of technology to improve lives. We pride ourselves on meeting timelines and ensuring lasting customer satisfaction through the appropriate use of our products and services.
          </p>
          
          <p style={{fontWeight:'normal'}}>At iMatrix Technologies, we synchronize our strengths to deliver exceptional results for our clients. Our unwavering commitment to continuous improvement ensures that we go beyond expectations, consistently upgrading our competencies to provide cutting-edge solutions. With an unrelenting zeal to serve, we are always ready to meet the ever-evolving needs of our clients.</p>
        </div>
        <div className="about-image">
          <img
            src="/images/aboutimages/abt_home-2.webp"
            alt="Innovative Solutions"
            className="image-style"
          />
        </div>
      </section>

      {/* Vision and Mission Section */}
      <section className="vision-mission">
        <div className="card2 vision" style={{marginLeft:'100px'}} >
          <h3>Our Vision</h3>
          <p>
          Our enduring mission is to create value by providing quality products that are delivered reliably and cost effectively by our inspired optimistic work for Our Customers.We create value by running an ethical business that offers equality of opportunities and career advancement, by rewarding their competence and performance for Our Employees.

          </p>
        </div>
        <div className="card2 mission" style={{marginLeft:'10px'}} >
          <h3>Our Mission</h3>
          <p>
          To become the most preferred service provider in IT-Sales, Service and Training in India and Abroad.
          </p>
        </div>
        <div className="card2 mission" style={{marginLeft:'10px'}}>
    <h3>Our Additional Mission</h3>
    <p>
      To foster innovation and creativity, ensuring that our services adapt to the evolving needs of our clients while contributing positively to the communities we serve.
    </p>
  </div>
      </section>

      {/* Values Section */}
      {/* <section className="values-section">

        <div className="values-container">
          <div className="value-item">
            <img src="/images/aboutimages/Our-value-Infographic-2.webp" alt="Trust" />
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default AboutPage;
