import React from "react";
import "./PaymentManagement.css"; // External CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faClock,
  faBalanceScale,
  faTasks,
  faCalculator,
  faHandHoldingUsd,
  faChartLine,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

const PaymentManagement = () => {
  const sections = [
    {
      title: "Receivable and payable management",
      content:
        "In TallyPrime, for each invoice, a unique bill reference is automatically created. Manage outstanding receivables and payables effortlessly, and keep track of due dates to ensure payments are on time.",
      icon: faFileInvoiceDollar,
    },
    {
      title: "Ageing analysis",
      content:
        "With TallyPrime’s ageing analysis report, you can easily identify overdue bills, track outstanding amounts, and ensure better cash flow by resolving payments.",
      icon: faClock,
    },
    {
      title: "Track receivables & payables as per MSME payment rule",
      content:
        "Registered MSMEs can efficiently track compliance with payment rules using TallyPrime’s features. Generate reports and summaries to help meet MSME regulatory requirements.",
      icon: faBalanceScale,
    },
    {
      title: "Multiple bill settlement",
      content:
        "Using bill settlement options in TallyPrime, you have a flexible and user-friendly system for managing multiple bill payments.",
      icon: faTasks,
    },
    {
      title: "Interest calculations",
      content:
        "When it comes to managing overdue receivables and payables, TallyPrime simplifies interest calculations by automatically applying predefined rules.",
      icon: faCalculator,
    },
    {
      title: "Cash and funds flow",
      content:
        "Get a clear picture of cash flow and funds using TallyPrime’s reports. Track payments and receipts, ensuring smooth functioning of the business.",
      icon: faHandHoldingUsd,
    },
    {
      title: "Cash flow projection",
      content:
        "Analyze expected inflows and outflows through cash flow projection reports in TallyPrime. Ensure better financial planning and decision-making.",
      icon: faChartLine,
    },
    {
      title: "Credit control techniques",
      content:
        "TallyPrime allows businesses to set credit limits, track overdue invoices, and send reminders to improve payment discipline.",
      icon: faCreditCard,
    },
  ];

  return (
    <div className="payment-container">
      {sections.map((section, index) => (
        <div
          className={`payment-section payment-section-${index}`}
          key={index}
        >
          <div className="payment-icon">
            <FontAwesomeIcon icon={section.icon} size="3x" />
          </div>
          <div className="payment-content">
            <h3>{section.title}</h3>
            <p>{section.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaymentManagement;
