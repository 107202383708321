import React, { useState } from "react";
import './TallySoftware.css';
import { FaCreditCard } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const TallySoftware = () => {
  // State to store selected years for each TSS edition
  const [selectedYearTssSilver, setSelectedYearTssSilver] = useState("1");
  const [selectedYearTssGold, setSelectedYearTssGold] = useState("1");
  const [selectedYearTssAuditor, setSelectedYearTssAuditor] = useState("1");
  const navigate = useNavigate(); // Hook for navigation
  // Price details for each TSS edition based on the selected year
  const priceDetails = {
    tssSilver: {
      "1": { price: 4500, gst: 810 },
      "2": { price: 8100, gst: 1458 },
    },
    tssGold: {
      "1": { price: 13500, gst: 2430 },
      "2": { price: 24300, gst: 4374 },
    },
    tssAuditor: {
      "1": { price: 6750, gst: 1215 },
      "2": { price: 12150, gst: 2187 },
    },
  };

  // Handle change in selected year for each TSS section
  const handleYearChange = (event, section) => {
    const { value } = event.target;
    switch (section) {
      case "tssSilver":
        setSelectedYearTssSilver(value);
        break;
      case "tssGold":
        setSelectedYearTssGold(value);
        break;
      case "tssAuditor":
        setSelectedYearTssAuditor(value);
        break;
      default:
        console.error("Invalid section:", section);
        break;
    }
  };

  // Get price details for each selected section and year
  const getPriceDetails = (section, year) => {
    if (priceDetails[section] && priceDetails[section][year]) {
      return priceDetails[section][year];
    } else {
      console.warn(`Price details not found for section: ${section}, year: ${year}`);
      return { price: 0, gst: 0 }; // Default values if undefined
    }
  };

  // Handle the 'Buy Now' button click
  const handleBuyNowClick = () => {
    navigate("/contact"); // Navigate to the contact page
  };

  return (
  
    <div className="tally-containeri">
      <Section
        title="What is TSS?"
        imageSrc="/images/Primelogo.webp"
        points={[
          "Tally Software Services (TSS) is a software subscription for a collection of services which add great value to your TallyPrime by giving you the latest developments in technology and statutory laws. Continuous product upgrades and Updates, connectivity driven functionalities such as online data exchange between your branches, remote access, seamless banking and payment services and much more, enhance your business performance by leaps and bounds."
        ]}
      />
          <Section
        title="Connected GST experience to upload and file GST returns"
        imageSrc="/images/tallysoftimg/connected-gst-experience-to-upload-and-file-gst-returns (1).svg"
        points={[
          "Automate and simplify GST return filing for businesses.",
          "Generate GSTR-1 and GSTR-3B with TallyPrime software.",
          "Access accurate details and validate GST compliance.",
          "Download reconciliation reports, invoices, and other forms.",
          "Easily upload returns and share GST data with your CA.",
          "Verify data with a single click, ensuring accuracy and compliance."
        ]}
      />

      <Section
        title="Connected services to generate e-invoices and e-way bills instantly"
        imageSrc="/images/tallysoftimg/CS-e-way-bills-instantly (1).png"
        points={[
          "Easily generate and manage e-invoices and e-way bills.",
          "Instantly validate documents and ensure GST compliance.",
          "Simplify GST filing processes and save time on documentation.",
          "Gain better visibility into billing and compliance requirements.",
          "Seamlessly integrate with TallyPrime for easy management."
        ]}
      />

      <Section
        title="TallyPrime with WhatsApp"
        imageSrc="/images/tallysoftimg/tss-image (1).png"
        points={[
          "Stay connected with your customers via WhatsApp.",
          "Share invoices, delivery notes, and receipts instantly.",
          "Offer better customer service and improve communication.",
          "Quickly resolve customer queries and provide updates."
        ]}
      />

      <Section
        title="Business Reports on Any Device, Anywhere"
        imageSrc="./images/tallysoftimg/Business-Reports-on-web-browser-1.png"
        points={[
          "Access business reports on the go with TallyPrime.",
          "Gain real-time insights into your business performance.",
          "Generate, view, and share reports from any device.",
          "Stay informed and make data-driven decisions."
        ]}
      />

<Section
        title="Digital Payment Request"
        imageSrc="./images/tallysoftimg/Payment-Gateway-Integration-1-1-1.png"
        points={[
          "Integrate with payment gateways and enjoy the benefits of a single system to generate and manage payment links. What’s more? You can do it with UPI as well.",
          "Supports integration with payment gateways such as PayU and Razorpay  for streamlined payments.",
          "Add payment QR code and link on the invoices, including bulk generation for multiple invoices.",
          "Generate payment links and share them with your customers through email and SMS."
        ]}
      />
      <Section
        title="Online Data Synchronisation"
        imageSrc="./images/tallyimg/4.webp"
        points={[
          "Decentralization of your books of accounts across branches is no more difficult. With TallyPrime’s data synchronization, you can synchronize data across your head office and branches, factories or warehouses.",
          "Exchange and consolidate Data across branches at click of a button",
          "Greater control on the direction of data synchronizes and data to be synchronized.",
          "Synchronize the data without needing to be ‘connected to each other live’ using on-demand synchronization"
        ]}
      />
      <Section
        title="Product updates"
        imageSrc="./images/product-updates (1).png"
        points={[
          "Regular product updates ensure that your TallyPrime caters to your ever-changing business requirements.",
          "Avail new product enhancements & major releases at frequent intervals",
          "Ensure compatibility with the latest technological and statutory changes.",
        ]}
      />
 {/* Tally Software Services Section (TSS) */}
 <h1 className="tally-prime-header">
        Tally Software Services (TSS) <br/>
        Price Details
      </h1>
      <div className="tally-prime-section2">
        {/* TSS Silver */}
        <div className="tally-prime-card2">
          <h2 className="tally-prime-card-title">
            TSS Silver
          </h2>
          <p>Single user edition</p>
          <p>
            <label>
              <input 
                type="radio" 
                name="tss-months-silver" 
                value="1" 
                checked={selectedYearTssSilver === "1"} 
                onChange={(e) => handleYearChange(e, "tssSilver")} 
              />
              1 Year
            </label>
            <label>
              <input 
                type="radio" 
                name="tss-months-silver" 
                value="2" 
                checked={selectedYearTssSilver === "2"} 
                onChange={(e) => handleYearChange(e, "tssSilver")} 
              />
              2 Years
            </label>
          </p>
          <h3 className="tally-prime-price">INR {getPriceDetails("tssSilver", selectedYearTssSilver).price}</h3>
          <p>+18% GST (INR {getPriceDetails("tssSilver", selectedYearTssSilver).gst})</p>
          <button className="tally-prime-button" onClick={handleBuyNowClick}>Renew</button>
        </div>

        {/* TSS Gold */}
        <div className="tally-prime-card2">
          <h2 className="tally-prime-card-title">
            TSS Gold
          </h2>
          <p>Multi-user edition</p>
          <p>
            <label>
              <input 
                type="radio" 
                name="tss-months-gold" 
                value="1" 
                checked={selectedYearTssGold === "1"} 
                onChange={(e) => handleYearChange(e, "tssGold")} 
              />
              1 Year
            </label>
            <label>
              <input 
                type="radio" 
                name="tss-months-gold" 
                value="2" 
                checked={selectedYearTssGold === "2"} 
                onChange={(e) => handleYearChange(e, "tssGold")} 
              />
              2 Years
            </label>
          </p>
          <h3 className="tally-prime-price">INR {getPriceDetails("tssGold", selectedYearTssGold).price}</h3>
          <p>+18% GST (INR {getPriceDetails("tssGold", selectedYearTssGold).gst})</p>
          <button className="tally-prime-button" onClick={handleBuyNowClick}>Renew</button>
        </div>

       {/* TSS Auditor */}
<div className="tally-prime-card2">
  <h2 className="tally-prime-card-title">
    TSS Auditor
  </h2>
  <p>Auditor Edition</p>
  <p>
    <label>
      <input 
        type="radio" 
        name="tss-months-auditor" 
        value="1" 
        checked={selectedYearTssAuditor === "1"} 
        onChange={(e) => handleYearChange(e, "tssAuditor")} 
      />
      1 Year
    </label>
    <label>
      <input 
        type="radio" 
        name="tss-months-auditor" 
        value="2" 
        checked={selectedYearTssAuditor === "2"} 
        onChange={(e) => handleYearChange(e, "tssAuditor")} 
      />
      2 Years
    </label>
  </p>
  <h3 className="tally-prime-price">INR {getPriceDetails("tssAuditor", selectedYearTssAuditor).price}</h3>
  <p>+18% GST (INR {getPriceDetails("tssAuditor", selectedYearTssAuditor).gst})</p>
  <button className="tally-prime-button" onClick={handleBuyNowClick}>Renew</button>
</div>
      </div>

    </div>
  );
};

const Section = ({ title, imageSrc, points }) => {
  return (
    <div className="tally-section">
      <div className="tally-section-content">
        <h2 className="tally-title">{title}</h2>
        <ul className="tally-points">
          {points.map((point, index) => (
            <li key={index} className="tally-point">{point}</li>
          ))}
        </ul>
      </div>
      <img src={imageSrc} alt={title} className="tally-section-image" />
    </div>
  );
};

export default TallySoftware;
