import React from "react";
import "./ReportDesign.css"; // External CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faClipboardList,
  faSearch,
  faSlidersH,
  faFileSignature,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

const ReportDesign = () => {
  const sections = [
    {
      title: "Intuitive and powerful dashboards",
      content:
        "Unlock the power of data visualization with powerful dashboards designed to provide insights at a glance. Monitor business performance through KPIs, trends, and comparisons, all in real-time.",
      icon: faChartBar,
    },
    {
      title: "Accurate and insightful reports at the blink of an eye",
      content:
        "TallyPrime is specifically designed to provide insightful reports instantly, enabling quick decision-making. Get actionable insights and summaries with in-depth data views.",
      icon: faClipboardList,
    },
    {
      title: "Designed to aid in analysis",
      content:
        "In TallyPrime, the built-in report designs are crafted to help businesses make better decisions. With powerful filters and customization options, you can create personalized reports.",
      icon: faSearch,
    },
    {
      title: "Getting around reports is easier",
      content:
        "With an intuitive interface and additional features like quick search and navigation, TallyPrime ensures a seamless experience while working with reports.",
      icon: faSlidersH,
    },
    {
      title: "Personalize your reports the way you want",
      content:
        "Using TallyPrime, you can personalize reports by choosing data views, filter options, and visual layouts to suit your specific needs.",
      icon: faFileSignature,
    },
    {
      title: "Powerful reports filter",
      content:
        "Quickly apply filters to reports based on specific fields to get refined data insights. The reports are dynamic and adaptable to your preferences.",
      icon: faFilter,
    },
  ];

  return (
    <div className="report-container">
      {sections.map((section, index) => (
        <div
          className={`report-section report-section-${index}`}
          key={index}
        >
          <div className="report-icon">
            <FontAwesomeIcon icon={section.icon} size="3x" />
          </div>
          <div className="report-content">
            <h3>{section.title}</h3>
            <p>{section.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportDesign;
