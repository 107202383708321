import React from 'react';
import './TallyDetails.css';

const TallyDetails = () => {
  return (
    <div className="tally-container">
      <h1>Tally.ERP 9</h1>
      <p>
        Tally.ERP 9 is <strong>preferred by millions</strong> as their business management software across the globe. It <strong>dynamically adapts</strong> to the way you operate your business and helps you simplify your business operations.
      </p>
      <p>
        Tally.ERP 9 provides you with exceptional capabilities that will simplify the way you manage all critical aspects of your business, including accounting, sales and purchase, inventory, manufacturing, taxation, payroll, MIS reporting, and much more.
      </p>

      <div className="tally-grid">
        <div className="grid-item">
          <i className="icon fa fa-briefcase"></i>
          <h3>Accounts</h3>
          <p>Book-keeping, Bill-wise details, Cost Centre tracking, Payables and receivables, Bank Reconciliation, Balance Sheet and P&L</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-percent"></i>
          <h3>Taxation</h3>
          <p>India - GST, TDS/TCS, VAT/CST, Excise, and Service Tax; GCC and Kenya - VAT</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-university"></i>
          <h3>Banking</h3>
          <p>e-Payments, e-Payments Report, Cost Centre, Reconciliation, Cheque management, Payment advice</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-chart-line"></i>
          <h3>Sales Management</h3>
          <p>Sales order, Stock groups, Stock query, Profit and loss, Price, Discounts, and Credit notes, Sales register</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-cart-plus"></i>
          <h3>Purchase Management</h3>
          <p>Purchase order, Item details, Cost, Expense and debit notes, Purchase register</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-users"></i>
          <h3>Payroll Management</h3>
          <p>Salary and payslip, Employee profile management, Payroll reports, PF/ESI calculations, Batch payments, Payment disbursal advice</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-cogs"></i>
          <h3>Manufacturing and Job work</h3>
          <p>Raw materials, Finished goods, WIP products, Multi-Bill of Material, Warehouse, Godown, Manufacturing journal</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-box"></i>
          <h3>Inventory</h3>
          <p>Batch processing, Units of measure, Inventory classification, Bill of materials</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-sync-alt"></i>
          <h3>Data Exchange</h3>
          <p>Data synchronization, Multiple format for reports, XML compatibility, Tally ODBC</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-lock"></i>
          <h3>Data Security</h3>
          <p>Secure connectivity, Secure transmission, Secure storage, Tally application access, TallyVault</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-globe"></i>
          <h3>Internet</h3>
          <p>E-mails and knowledge base, Licence and user management, Jobs and recruitments, SMS notification and Natural Language support</p>
        </div>

        <div className="grid-item">
          <i className="icon fa fa-star"></i>
          <h3>Miscellaneous</h3>
          <p>Multi-Currency support, Data Migration, Logo printing, Splitting and merging of company, Auto-voucher numbering</p>
        </div>
      </div>
    </div>
  );
};

export default TallyDetails;
