import React, { useState } from 'react';
import Confetti from 'react-confetti';
import './BankReconciliation.css';

const BankReconciliation = () => {
  const [isConfetti, setIsConfetti] = useState(false); // You can now control the confetti with this state

  return (
    <div className="content-container">
      {isConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      <section className="feature">
        <h2><i className="fa fa-credit-card"></i> Auto Bank Reconciliation</h2>
        <p>
          Reconciling bank statements with a long list of transactions is no more stressful! With TallyPrime, you just need to import the e-statement downloaded from the bank portal, and reconciliation will happen automatically. Supports bank reconciliation for more than 100 banks.
        </p>
        <p>
          This feature helps businesses save valuable time by automating the entire reconciliation process. The system matches entries automatically, reducing the possibility of human error. It also supports a wide range of banks, making it accessible to users worldwide.
        </p>
        <p>
          The process is quick, simple, and highly accurate, ensuring that your bank statements are always up-to-date and reconciled without the hassle.
        </p>
      </section>

      <section className="feature">
        <h2><i className="fa fa-money-check-alt"></i> Generate Cash Deposit Slips and Payment Advice</h2>
        <p>
          Is depositing cash or cheques an everyday activity for you? Get rid of the hassle by generating cash and cheque deposit slips from TallyPrime. You only have to provide the denomination of notes, and TallyPrime does the rest.
        </p>
        <p>
          Whether you're handling daily deposits or monthly settlements, this feature streamlines the process and ensures that you can complete your transactions quickly and securely.
        </p>
        <p>
          TallyPrime’s intuitive interface makes generating deposit slips an effortless task. You can also use this feature to track and monitor deposits for better financial management and reporting.
        </p>
      </section>

      <section className="feature">
        <h2><i className="fa fa-credit-card"></i> Cheque Management</h2>
        <p>
          TallyPrime provides an effective cheque management system by allowing the creation of multiple cheque books with unique ranges for every bank. The cheque register helps you view, search, and track your cheque details.
        </p>
        <p>
          Whether you’re managing personal or business cheques, this feature offers full tracking capabilities. You can record issued cheques, track their clearance, and manage cheque statuses easily.
        </p>
        <p>
          This ensures smooth management of cheques, preventing issues like duplicate cheques, missed payments, and confusion with cheque numbers.
        </p>
      </section>

      <section className="feature">
        <h2><i className="fa fa-exchange-alt"></i> e-Payments</h2>
        <p>
          The e-Banking module of TallyPrime acts as a seamless interface between the bank and your business. Using the e-Payments feature, you can simplify the payment process with the utmost security for your financial data.
        </p>
        <p>
          With e-Payments, you can make payments through various methods, such as e-Fund Transfer, Electronic Cheque, or Electronic DD/PO, from one bank to another. All you need to do is export your transactions from Tally and upload them on the portal. Voila, the payment is done!
        </p>
        <p>
          This feature ensures that all financial transactions are processed quickly, securely, and accurately, saving time and reducing errors. Most popular banks are supported for e-payments, ensuring smooth integration with your financial operations.
        </p>
        <p>
          Additionally, TallyPrime tracks all e-payment transactions, providing you with full visibility and control over your financial activities.
        </p>
      </section>

      <section className="feature">
        <h2><i className="fa fa-sync-alt"></i> Real-Time Synchronization</h2>
        <p>
          TallyPrime offers real-time synchronization with various financial platforms. This means that your bank statements, payment data, and other financial information are always up-to-date, ensuring accuracy across all systems.
        </p>
        <p>
          This feature allows businesses to easily reconcile accounts, make timely decisions, and avoid discrepancies between their accounting software and banking records.
        </p>
        <p>
          The synchronization process is automated, reducing manual errors and saving valuable time in updating financial data.
        </p>
      </section>

      <section className="feature">
        <h2><i className="fa fa-cogs"></i> Customizable Settings</h2>
        <p>
          TallyPrime provides customizable settings to suit your business needs. From bank reconciliation preferences to payment method options, you can tailor the system to match your exact requirements.
        </p>
        <p>
          Whether you are working with different currencies, adjusting tax rates, or setting payment limits, TallyPrime makes it easy to configure and optimize your workflow.
        </p>
        <p>
          With flexible settings, businesses can operate more efficiently and reduce the risk of mistakes, while ensuring that they meet their financial and regulatory requirements.
        </p>
      </section>
    </div>
  );
};

export default BankReconciliation;
