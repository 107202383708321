import React, { useState } from "react";
import { FaCheckCircle, FaShoppingCart } from "react-icons/fa";
import "./plans.css";
import TallyPrimePlan from './TallyPrimePlan'; 
import { useNavigate } from "react-router-dom";
// Pricing plan data
const plans = [
  {
    category: "TallyPrime",
    options: [
      
      {
        title: "SILVER",
        prices: ["INR 22,500 + 18% GST (INR 4,050)"],
        features: [
          "Single user edition",
          "For Standalone PCs",
          "Perpetual license",
          "Zero cost EMI available",
        ],
        durations: [],
      },
     
      {
        title: "GOLD",
        prices: ["INR 67,500 + 18% GST (INR 12,150)"],
        features: [
          "Unlimited multi-user edition",
          "For multiple PCs on LAN environment",
          "Perpetual license",
          "Zero cost EMI available",
        ],
        durations: [],
      },
    ],
  },
 
  // {
  //   category: "TallyPrime Server",
  //   options: [
  //     {
  //       title: "PERPETUAL",
  //       prices: ["INR 2,70,000 + 18% GST (INR 48,600)"],
  //       features: [
  //         "For medium and large enterprises",
  //         "TallyPrime Server works with TallyPrime Gold license",
  //       ],
  //       durations: [],
  //     },
  //   ],
  // },
];

// Component for rendering individual pricing cards
const PricingCard = ({ title, prices, features, durations = [] }) => {
  const [selectedDuration, setSelectedDuration] = useState(0); // Default to first duration (0 = no selection)
  const navigate = useNavigate(); // Hook for navigation

  const handleDurationChange = (index) => {
    setSelectedDuration(index); // Update selected duration
  };
  const handleBuyNowClick = () => {
    navigate("/contact"); // Navigate to the contact page
  };
  return (
    <div className="pricing-card-item">
      <h3>{title}</h3>
      <p className="pricing-card-price">
        {prices[selectedDuration] || prices[0]} {/* Show selected price or default */}
      </p>
      <ul>
        {features.map((feature, index) => (
          <li key={index} className="pricing-card-feature">
            <FaCheckCircle className="pricing-card-icon" /> {feature}
          </li>
        ))}
      </ul>
      {durations.length > 0 && (
        <div className="duration-options">
          <p>Select Duration (Months):</p>
          {durations.map((duration, index) => (
            <label key={duration}>
              <input
                type="radio"
                name={`duration-${title}`}
                value={duration}
                checked={selectedDuration === index}
                onChange={() => handleDurationChange(index)}
              />
              {duration}
            </label>
          ))}
        </div>
      )}
      <button className="pricing-card-buy-now"       onClick={handleBuyNowClick}
      >
        <FaShoppingCart className="pricing-card-icon" /> Buy Now
      </button>
    </div>
  );
};

// Component for rendering categories of pricing plans
const PricingCategory = ({ category, options }) => (
  <div className="pricing-category-item">
    <h2 className="pricing-category-title">{category}</h2>
    <div className="pricing-category-options">
      {options.map((option, index) => (
        <PricingCard key={index} {...option} />
      ))}
    </div>
  </div>
);

// Main component for rendering the entire pricing table
const PricingTable = () => (
  <div className="pricing-table-container">
    {plans.map((plan, index) => (
      <PricingCategory key={index} {...plan} />
    ))}

    <TallyPrimePlan/>
  </div>
);

export default PricingTable;
