import React from 'react';
import { FaCloud, FaMoneyBill, FaClock, FaCogs, FaUsers, FaDesktop } from 'react-icons/fa'; // importing relevant icons
import './TallyAws.css';

const TallyAws = () => {
    return (
        <div className="tallyprime-container">
            <br />
            <br />
            <h1 className="main-title">Why choose TallyPrime powered by AWS</h1>
            
            <div className="feature reliability-feature">
                <div className="feature-icon">
                    <FaCloud size={40} color="#ffffff" /> {/* Reliability Icon */}
                </div>
                <div className="feature-content">
                    <h2>Reliability</h2>
                    <p>
                        You are familiar with the trust and simplicity of Tally products, and with AWS you will find expertise in cloud platform.
                        TallyPrime on AWS has been worked upon by teams in Tally & AWS to make sure it works for you anytime, anywhere.
                    </p>
                </div>
            </div>

            <div className="feature everything-feature">
                <div className="feature-icon">
                    <FaUsers size={40} color="#ffffff" /> {/* Everything in One Place Icon */}
                </div>
                <div className="feature-content">
                    <h2>Everything you need in one place</h2>
                    <p>
                        If you choose to go with this option, all you need to do is to talk to your Tally partner about it. They will lead the entire engagement from requirements gathering to implementation.
                    </p>
                </div>
            </div>

            <div className="feature economical-feature">
                <div className="feature-icon">
                    <FaMoneyBill size={40} color="#ffffff" /> {/* Economical Icon */}
                </div>
                <div className="feature-content">
                    <h2>Economical</h2>
                    <p>
                        TallyPrime on AWS will be available to you at very affordable price points, starting as low as 800 INR/month per user with unlimited usage.
                    </p>
                </div>
            </div>

            <div className="feature access-feature">
                <div className="feature-icon">
                    <FaClock size={40} color="#ffffff" /> {/* 24/7 Access Icon */}
                </div>
                <div className="feature-content">
                    <h2>24/7 Access and Unlimited Usage</h2>
                    <p>
                        Concerned about data usage or limited access times? With TallyPrime on AWS, you get unlimited usage and unrestricted access. Enjoy 24/7 access with no limit on usage time.
                    </p>
                </div>
            </div>

            <div className="feature flexibility-feature">
                <div className="feature-icon">
                    <FaCogs size={40} color="#ffffff" /> {/* Flexibility Icon */}
                </div>
                <div className="feature-content">
                    <h2>Flexibility</h2>
                    <p>
                        Not sure which usage plan works best for you? Need to add more users? You can seamlessly upgrade or downgrade your plan whenever you like.
                    </p>
                </div>
            </div>

            <div className="feature platform-agnostic-feature">
                <div className="feature-icon">
                    <FaDesktop size={40} color="#ffffff" /> {/* Platform Agnostic Icon */}
                </div>
                <div className="feature-content">
                    <h2>Platform Agnostic</h2>
                    <p>
                        The solution works across Windows, Mac, and Linux clients when accessed through a Chrome browser even with different system configurations.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TallyAws;
