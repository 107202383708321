import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './homepage/Home';
import IMatrixComponent from './hero/IMatrixComponent';
import TallyPrime from './tallyprime/TallyPrime';
import TallyShowcase from './tallyprime/TallyShowcase';
import Footer from './footer/Footer';
import Timeline from './timelines/Timeline';
import Content_New from './content_new/Content_New';
import Client from './Client/Client';
import AboutPage from './AboutPage/AboutPage';
import HardwareServices from './HardwareServices/HardwareServices';
import SecuritySystem from './SecuritySystem/SecuritySystem';
import MobileDeveloper from './MobileDeveloper/MobileDeveloper';
import WebDevelopment from './WebDevelopment/WebDevelopment';
import ServicePage from './ServicePage/ServicePage';
import ServerPage from './ServerPage/ServerPage';
import ProductGrid from './BIO/ProductGrid';
import Antivirus from './AntivirusF/Antivirus';
import AutoBackupSoftware from './autoBackupSoftware/AutoBackupSoftware';
import PrinterSoftware from './PrinterSoftware/PrinterSoftware';
import WebList from './webcat/WebList';
import EWarehouse from './e_data/EWarehouse';
import PRPurchaseRequest from './pr/PRPurchaseRequest';
import TransportWebApplication from './tr/TransportWebApplication';
import './App.css';
import Header from './homepage/Header';
import ScrollToTop from './ScrollToTop';
import NotFound from './NotFound';
import CareerPage from './careers_pages/CareerPage';
import TallySoftware from './tallyprime/tallysoft/TallySoftware';
import TallyPrimeServer from './tallyprime/tallyprime/TallyPrimeServer';
import TallyAws from './tallyprime/tallyprimeaws/TallyAws';
import WhatsAppBusiness from './tallyprime/whatapp/WhatsAppBusiness';
import TallyPlatform from './tallyprime/custom/TallyPlatform';
import TallyDefinition from './tallyprime/TDL/TallyDefinition';
import PrimeDeatils from './tallyprime/prime/PrimeDeatils';
import TallyDetails from './tallyprime/ERP/TallyDetails';
import BizAnalyst from './tallyprime/analyst/BizAnalyst';
import PlansAndPricing from './tallyprime/analyst/PlansAndPricing';

import OurService from './tallyprime/tallyourservice/OurService';
import InventoryManagement from './tallyprime/tallyprimefeildspages/InventoryManagement';
import ReportDesign from './tallyprime/tallyprimefeildspages/ReportDesign';
import PaymentManagement from './tallyprime/tallyprimefeildspages/PaymentManagement';
import Taxation from './tallyprime/tallyprimefeildspages/Taxation';
import InvoiceFeature from './tallyprime/tallyprimefeildspages/InvoiceFeature';
import BankReconciliation from './tallyprime/tallyprimefeildspages/BankReconciliation';
import ConnectedServices from './tallyprime/tallyprimefeildspages/ConnectedServices';
import Plans from './tallyprime/tallyprimefeildspages/plans'; 
import TallyPrimePlan from './tallyprime/tallyprimefeildspages/TallyPrimePlan'; 
import Gallery from './Gallery/Gallery'; 





function App() {
  return (
    <div className="App">
      <Header />
     
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/imatrix" element={<IMatrixComponent />} />
        <Route path="/tallyprime" element={<TallyPrime />} />
        <Route path="/tallyshowcase" element={<TallyShowcase />} />
        <Route path="/timelines" element={<Timeline />} />
        <Route path="/contact" element={<Content_New />} />
        <Route path="/Client" element={<Client />} />
        <Route path="/AboutPage" element={<AboutPage />} />
        <Route path="/HardwareServices" element={<HardwareServices />} />
        <Route path="/SecuritySystem" element={<SecuritySystem />} />
        <Route path="/MobileDeveloper" element={<MobileDeveloper />} />
        <Route path="/WebDevelopment" element={<WebDevelopment />} />
        <Route path="/ServicePage" element={<ServicePage />} />
        <Route path="/ServerPage" element={<ServerPage />} />
        <Route path="/BIO" element={<ProductGrid />} />
        <Route path="/Antivirus" element={<Antivirus />} />
        <Route path="/autoBackupSoftware" element={<AutoBackupSoftware />} />
        <Route path="/PrinterSoftware" element={<PrinterSoftware />} />
        <Route path="/webcat" element={<WebList />} />
        <Route path="/e_data" element={<EWarehouse />} />
        <Route path="/pr" element={<PRPurchaseRequest />} />
        <Route path="/tr" element={<TransportWebApplication />} />
        <Route path="/career_pages" element={<CareerPage />} />
        <Route path="/TallySoftware" element={<TallySoftware />} />
        <Route path="/TallyPrimeServer" element={<TallyPrimeServer />} />
        <Route path="/TallyAws" element={<TallyAws />} />
        <Route path="/WhatsAppBusiness" element={<WhatsAppBusiness />} />
        <Route path="/TallyPlatform" element={<TallyPlatform />} />
        <Route path="/TallyDefinition" element={<TallyDefinition />} />
        <Route path="/PrimeDeatils" element={<PrimeDeatils />} />
        <Route path="/TallyDetails" element={<TallyDetails />} />
        <Route path="/BizAnalyst" element={<BizAnalyst />} />
        <Route path="/OurService" element={<OurService/>} />
        <Route path="/InventoryManagement" element={<InventoryManagement/>} />
        <Route path="/ReportDesign" element={<ReportDesign/>} />
        <Route path="/PaymentManagement" element={<PaymentManagement/>} />
                <Route path="/Taxation" element={<Taxation/>} />
                <Route path="/InvoiceFeature" element={<InvoiceFeature/>} />
                <Route path="/BankReconciliation" element={<BankReconciliation/>} />
                <Route path="/ConnectedServices" element={<ConnectedServices/>} />
                <Route path="/plans" element={<Plans/>} />
                <Route path="/TallyPrimePlan" element={<TallyPrimePlan/>} />
                <Route path="/Gallery" element={<Gallery/>} />

                <Route path="/PlansAndPricing" element={<PlansAndPricing/>} />



            


        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
